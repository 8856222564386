import React from "react";
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from "moment";

import { fn_axios_post_excel_down, fn_axios, getCookieUserId, CONSTANTS } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";

function CmptManage() {

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  // const cmptLId = queryParams.get('cmptLId');

  const [cmptLId, setCmptLId] = useState(queryParams.get('cmptLId'));
  const [cmptManageList, setCmptManageList] = useState([]);
  const [headerList, setHeaderList] = useState([]);
  const [cntVo, setCntVo] = useState(null);

  const [pageNum, setPageNum] = useState((queryParams.get('pageNum') == null || queryParams.get('pageNum') == "") ? 1 : parseInt(queryParams.get('pageNum')));
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  const [reqRsltCdList, setReqRsltCdList] = useState([]);
  const [selectedReqRsltCd, setSelectedReqRsltCd] = useState('');
  const [selectedOrdColumn, setSelectedOrdColumn] = useState('');




  const getCode = () => {
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/${API.VERSION}/code/getCode`,
      params: {
        codeMCd: "REQ_RSLT_CD" // 체육관 종류 코드
        , orderColum: "code_l_nm"
      }
    };
    fn_axios(param, callbackGetCode, null);
  }

  const callbackGetCode = (res) => {
    setReqRsltCdList(res.data.object);
  }

  const handleReqRsltCd = (e) => {
    setSelectedReqRsltCd(e.target.value);
    // onSelectedLareaAddr(e.target.value);
  }
  const handleOrdColumn = (e) => {
    setSelectedOrdColumn(e.target.value);
  }

  const getCmptManagePaging = (num, lId) => {
    // setBoard(null);
    if (lId == null || lId == "") {
      lId = cmptLId
    }
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/${API.VERSION}/mng/getCmptManagePaging`,
      params: {
        pageNum: num
        , searchKeyword: searchKeyword
        , reqRsltCd: selectedReqRsltCd
        , ordColumn: selectedOrdColumn
        , cmptLId: lId
      }
    };
    fn_axios(param, callbackGetCmptManagePaging, null);
  }

  const callbackGetCmptManagePaging = (res) => {
    setCmptManageList(res.data.object);
    settingHeaderList(res.data.object);
    setCntVo(res.data.map.cntVo);
    console.log(res);
    var tempInt = parseInt(res.data.map.cntVo.cnt % pageCnt);
    if (tempInt != 0) {
      tempInt = parseInt(res.data.map.cntVo.cnt / pageCnt) + 1;
    } else {
      tempInt = parseInt(res.data.map.cntVo.cnt / pageCnt);
    }
    settingPasing(tempInt);
  }

  const settingHeaderList = (obj) => {
    if (obj != null && obj.length > 0) {
      var tempList = ["no"
        , "이름"
        , "전화번호"
        , "이메일"
        , "생년월일"
        , "주소"
        , "상세주소"
        , "세부종목명"
        , "팀명"
        , "상태"
        , "결제/취소일시"
      ];
      if (obj[0].gameDivsNm0 != null && obj[0].gameDivsNm0 != "") tempList = [...tempList, obj[0].gameDivsNm0]
      if (obj[0].gameDivsNm1 != null && obj[0].gameDivsNm1 != "") tempList = [...tempList, obj[0].gameDivsNm1]
      if (obj[0].gameDivsNm2 != null && obj[0].gameDivsNm2 != "") tempList = [...tempList, obj[0].gameDivsNm2]
      if (obj[0].gameDivsNm3 != null && obj[0].gameDivsNm3 != "") tempList = [...tempList, obj[0].gameDivsNm3]
      if (obj[0].gameDivsNm4 != null && obj[0].gameDivsNm4 != "") tempList = [...tempList, obj[0].gameDivsNm4]
      if (obj[0].gameDivsNm5 != null && obj[0].gameDivsNm5 != "") tempList = [...tempList, obj[0].gameDivsNm5]
      if (obj[0].gameDivsNm6 != null && obj[0].gameDivsNm6 != "") tempList = [...tempList, obj[0].gameDivsNm6]
      if (obj[0].gameDivsNm7 != null && obj[0].gameDivsNm7 != "") tempList = [...tempList, obj[0].gameDivsNm7]
      if (obj[0].gameDivsNm8 != null && obj[0].gameDivsNm8 != "") tempList = [...tempList, obj[0].gameDivsNm8]
      if (obj[0].gameDivsNm9 != null && obj[0].gameDivsNm9 != "") tempList = [...tempList, obj[0].gameDivsNm9]
      setHeaderList(tempList);
    }

  }
  const getCmptManageExcelDown = (lId) => {
    // setBoard(null);
    if (lId == null || lId == "") {
      lId = cmptLId
    }
    const param = {
      url: `${API.BACKEND}/${API.VERSION}/mng/getCmptManageExcelDown`,
      params: {
        searchKeyword: searchKeyword
        , reqRsltCd: selectedReqRsltCd
        , headerList: headerList
        , cmptLId: lId
      }
    };
    fn_axios_post_excel_down(param, null, null);
  }


  const settingPasing = (maxNum) => {
    setPageMaxNum(parseInt(maxNum));
    // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
    var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2);
    var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) + 2;
    var tempList = [];

    if (maxNum != 0) {
      for (var i = start; i <= end; i++) {
        tempList.push(i);
      }
    }
    setPaging(tempList);
  }
  const onClickPaging = (e) => {
    setPageNum(parseInt(e.target.text));
    //getPointPage(e.target.text);

    return null;
  }

  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

  const onClickSearch = () => {
    if (pageNum == 1) {
      getCmptManagePaging(pageNum, cmptLId);
    } else {
      setPageNum(1);
    }

  }
  const onClickExcelDown = () => {
    getCmptManageExcelDown(cmptLId);
  }


  useEffect(() => {
    if (getCookieUserId() != "21" && getCookieUserId() != "300" && getCookieUserId() != "308" && getCookieUserId() != "912" && getCookieUserId() != "322") {
      alert("관리자만 사용 가능합니다.");
      window.location.href = `/`;
    }
    if (cmptLId) {
      getCmptManagePaging(pageNum, cmptLId);
    } else {
      if (getCookieUserId() === 21 || getCookieUserId() === 912 || getCookieUserId() === 322) {
        // setCmptLId('240318_GAME_KIND_0004_000001_0001');
        setCmptLId('240821_GAME_KIND_0016_000001_0001');
        // setCmptLId('240318_GAME_KIND_0004_000001_0001');
        getCmptManagePaging(pageNum, '240821_GAME_KIND_0016_000001_0001');
      } else if (getCookieUserId() === 21 || getCookieUserId() === 308) {
        // setCmptLId('240318_GAME_KIND_0004_000001_0001');
        setCmptLId('240215_GAME_KIND_0020_000002_0118');
        // setCmptLId('240318_GAME_KIND_0004_000001_0001');
        getCmptManagePaging(pageNum, '240215_GAME_KIND_0020_000002_0118');
      } else if (getCookieUserId() === 300) {
        setCmptLId('240321_GAME_KIND_0004_000002_0001');
        getCmptManagePaging(pageNum, '240321_GAME_KIND_0004_000002_0001');
      }
    }


  }, [pageNum]);
  const cancelCardForPayId = (payId) => {
    const param = {
      method: 'POST',
      url: `${API.BACKEND}/${API.VERSION}/kiwoompay/cancelCardForPayId`,
      params: {
        cmptLId: cmptLId
        // , cmptGameId : cmptGameId
        , payId: payId
        , reqUserId: getCookieUserId()
        , PAYMETHOD: 'CARD'
        , CANCELREASON: "대회 관리자에 의한 취소"
      }
    }
    fn_axios(param, callbackCancelCard, null);
  }
  const callbackCancelCard = (res) => {
    if (res.data.status) {
      alert(res.data.message);
      getCmptManagePaging(pageNum, cmptLId);
    } else {
      alert(res.data.message);
    }
  }

  useEffect(() => {
    if (reqRsltCdList == null || reqRsltCdList.length == 0) {
      getCode();
    }
  }, []);
  useEffect(() => {
  }, [cmptManageList]);

  return (
    <Layout>
      <div className="contents ground">
        <h1 className="sub_title">대회 현황 / (임시)</h1>

        <section className="ground_list list_type1">
          {/* <!-- list /top search --> */}
          <div className="bar">
            <div className="tx_type03 odr2">전체 <b>{cntVo == null ? "0" : cntVo.cnt}</b>건 / 결제완료 : <b>{cntVo == null ? "0" : cntVo.cntCpmt + "(남자:" + cntVo.cntCpmtMan + ", 여자:" + cntVo.cntCpmtWoman + ")/" + cntVo.cntCpmt}</b></div>
            <div className="search_box odr1">
              <select style={{ minWidth: "50px" }} value={selectedOrdColumn} onChange={handleOrdColumn}  >
                <option value="" >정렬</option>
                <option key={1} value={'REQ_DTM'} >신청일자</option>
                <option key={1} value={'ENTER_TEAM_NM'} >팀명</option>
              </select>
              <select style={{ minWidth: "50px" }} name="gymType" id="gymType" value={selectedReqRsltCd} onChange={handleReqRsltCd}  >
                <option value="" >신청상태</option>
                {reqRsltCdList.map((item, index) => (
                  <option key={index} value={item.codeLCd} >
                    {item.codeLNm}
                  </option>
                ))}
              </select>
              <input type="text" style={{ minWidth: "90px" }} name="keyword" value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드" />
              <button onClick={onClickSearch}>검색</button>
              <button onClick={onClickExcelDown}>Excel Download</button>
            </div>
            {/* <div className="list_date odr3">

                </div> */}
          </div>
          {/* <!-- //list top search --> */}
          {/* <!-- table pc ver--> */}
          <div className="table_type1" style={{ overflowX: "auto" }}>
            {cmptManageList != null && cmptManageList.length > 0 ? null :
              <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "auto" }}>
                <colgroup>
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                </colgroup>
                <thead>
                  <th>no</th>
                  <th>이름</th>
                  <th>전화번호</th>
                  <th>이메일</th>
                  <th>세부종목명</th>
                  <th>상태</th>
                  <th>결제/취소일시</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "center" }} colSpan={7}>검색된 결과 없습니다.
                    </td>
                  </tr>
                </tbody>
              </table>
            }
            {cmptManageList == null || cmptManageList.length == 0 ? null :
              <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "auto" }}>
                <colgroup>
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  <col style={{ width: "auto" }} />
                  {cmptManageList[0].gameDivsNm0 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm1 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm2 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm3 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm4 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm5 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm6 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm7 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm8 == null ? null : <col style={{ width: "auto" }} />}
                  {cmptManageList[0].gameDivsNm9 == null ? null : <col style={{ width: "auto" }} />}
                </colgroup>
                <thead>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>no</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>결제취소</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>이름</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>전화번호</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>이메일</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>세부종목명</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>상태</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>팀명</th>
                  <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>결제/취소일시</th>
                  {cmptManageList[0].gameDivsNm0 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm0}</th>}
                  {cmptManageList[0].gameDivsNm1 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm1}</th>}
                  {cmptManageList[0].gameDivsNm2 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm2}</th>}
                  {cmptManageList[0].gameDivsNm3 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm3}</th>}
                  {cmptManageList[0].gameDivsNm4 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm4}</th>}
                  {cmptManageList[0].gameDivsNm5 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm5}</th>}
                  {cmptManageList[0].gameDivsNm6 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm6}</th>}
                  {cmptManageList[0].gameDivsNm7 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm7}</th>}
                  {cmptManageList[0].gameDivsNm8 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm8}</th>}
                  {cmptManageList[0].gameDivsNm9 == null ? null : <th style={{ whiteSpace: "nowrap", paddingLeft: "4px" }}>{cmptManageList[0].gameDivsNm9}</th>}
                </thead>
                <tbody>
                  {cmptManageList.map((item, index) => (
                    <tr key={index}>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.no}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>
                        {item.reqRsltCd == CONSTANTS.REQ_RSLT_CD_02 ?
                          <button className="btn_type3" onClick={() => { if (window.confirm("결제를 취소하시겠습니까?")) { cancelCardForPayId(item.payId); } }}>결제 취소</button>
                          :
                          ""
                        }
                        {/* <button className="btn_type3" onClick={() => cancelCard(pgPayList[index])}>결제 취소</button> */}

                      </td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.userNm}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.userPhone}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.userEmail}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.gameLNm}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.reqRsltNm}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.enterTeamNm}</td>
                      <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptCancelDtm == null ? moment(item.cmptReqDtm).format('YYYY-MM-DD HH:mm') : moment(item.cmptCancelDtm).format('YYYY-MM-DD HH:mm')}</td>
                      {item.gameDivsNm0 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue0 == null ? (item.gameClsfKindCd0 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue0) : item.cmptReqGameDivsValue0}</td>}
                      {item.gameDivsNm1 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue1 == null ? (item.gameClsfKindCd1 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue1) : item.cmptReqGameDivsValue1}</td>}
                      {item.gameDivsNm2 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue2 == null ? (item.gameClsfKindCd2 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue2) : item.cmptReqGameDivsValue2}</td>}
                      {item.gameDivsNm3 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue3 == null ? (item.gameClsfKindCd3 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue3) : item.cmptReqGameDivsValue3}</td>}
                      {item.gameDivsNm4 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue4 == null ? (item.gameClsfKindCd4 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue4) : item.cmptReqGameDivsValue4}</td>}
                      {item.gameDivsNm5 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue5 == null ? (item.gameClsfKindCd5 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue5) : item.cmptReqGameDivsValue5}</td>}
                      {item.gameDivsNm6 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue6 == null ? (item.gameClsfKindCd6 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue6) : item.cmptReqGameDivsValue6}</td>}
                      {item.gameDivsNm7 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue7 == null ? (item.gameClsfKindCd7 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue7) : item.cmptReqGameDivsValue7}</td>}
                      {item.gameDivsNm8 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue8 == null ? (item.gameClsfKindCd8 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue8) : item.cmptReqGameDivsValue8}</td>}
                      {item.gameDivsNm9 == null ? null : <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.cmptReqGameDivsValue9 == null ? (item.gameClsfKindCd9 == CONSTANTS.GAME_CLSF_KIND_CD_05 ? '' : item.gameDivsLValue9) : item.cmptReqGameDivsValue9}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            }
          </div>
          {/* <!-- //table pc ver--> */}
          {/* <!-- paging --> */}
          {cmptManageList == null ? null :
            <div className="paging">
              {paging.map((item, index) => (
                <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" : ""}>{item}</a>
              ))}
            </div>
          }
          {/* <!-- //paging --> */}
        </section>
      </div>
    </Layout>
  );
}

export default CmptManage;
