import React from "react";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';


import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";
import TeamSelector from "../../common/TeamSelector.tsx";
import PartnerSelector from "../../common/PartnerSelector.tsx";

function CmptReqs() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const gameKindNm = queryParams.get('gameKindNm');
  const [cmpt, setCmpt] = useState(null);
  const [popup, setPopup] = useState();
  const [cmptGameList, setCmptGameList] = useState([]);
  const [EnterReqList, setEnterReqList] = useState([]);
  const [cmptGameDivsMList, setCmptGameDivsMList] = useState([]);
  const [cmptGameDivsList1, setCmptGameDivsList1] = useState([]);
  const [cmptGameDivsList2, setCmptGameDivsList2] = useState([]);
  const [cmptGameDivsList3, setCmptGameDivsList3] = useState([]);

  const [cmptGameDivs1, setCmptGameDivs1] = useState(null);
  const [cmptGameDivs2, setCmptGameDivs2] = useState(null);
  const [cmptGameDivs3, setCmptGameDivs3] = useState(null);

  const [pageNum, setPageNum] = useState(1);
  const [pageMaxNum, setPageMaxNum] = useState(0);
  const [pageCnt, setPageCnt] = useState(10);
  const [paging, setPaging] = useState([]);
  // const [searchKeyword, setSearchKeyword] = useState("");
  

  const handleCmptGameDivs1 = (e) => {
      setCmptGameDivs1(e.target.value);
  }
  const handleCmptGameDivs2 = (e) => {
      setCmptGameDivs2(e.target.value);
  }
  const handleCmptGameDivs3 = (e) => {
      setCmptGameDivs3(e.target.value);
  }

  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getCmpt`,
        params: {cmptLId : cmptLId},
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  

    // setSignguDataList(resList);
    setCmpt(res.data.map.cmpt[0]);
  }

  const getCmptGameDivs = () => {
      const param = {
        method : 'GET',
        url : `${API.BACKEND}/${API.VERSION}/cmptGame/getCmptGameDivs`,
        params : {    cmptLId: cmptLId
                     , mdtrGameOptnYn : "Y"
                      // , cmptGameId : cmptGame.cmptGameId
                  }
        }
      fn_axios(param,callbackGetCmptGameDivs,null);
  }
    
  const callbackGetCmptGameDivs = (res) => {
      console.log(res);
      setCmptGameDivsList1(res.data.map.cmptGameDivsL1);
      setCmptGameDivsList2(res.data.map.cmptGameDivsL2);
      setCmptGameDivsList3(res.data.map.cmptGameDivsL3);
      setCmptGameDivsMList(res.data.map.cmptGameDivsM);
  }

  const getCmptGamePaging = (num) => {
      setCmptGameList(null);
      var tempList = [];
      if(cmptGameDivs1 != null && cmptGameDivs1 != "") {
          tempList.push(cmptGameDivs1);
      }
      if(cmptGameDivs2 != null && cmptGameDivs2 != "") {
          tempList.push(cmptGameDivs2);
      }
      if(cmptGameDivs3 != null && cmptGameDivs3 != "") {
          tempList.push(cmptGameDivs3);
      }
      console.log(tempList);

      const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/cmptGame/getCmptGamePaging`,
      params : {    cmptLId: cmptLId
                    , pageNum : num
                    , mdtrGameOptnYn : "Y"
                  //   , searchKeyword : searchKeyword
                    , gameDivsLList : tempList.join(",")
                    , userId : getCookieUserId()
                }
    }
    fn_axios(param,callbackGetCmptGamePaging,null);
  
  }
  const callbackGetCmptGamePaging = (res) => {
      setCmptGameList(res.data.object);
        var tempInt  = parseInt(res.data.map.cnt%pageCnt);
        if(tempInt != 0 ) {
            tempInt = parseInt(res.data.map.cnt/pageCnt) + 1;
        } else {
            tempInt = parseInt(res.data.map.cnt/pageCnt);
        }
        settingPasing(tempInt);
  }

  const settingPasing = (maxNum) => {
      setPageMaxNum(parseInt(maxNum));
      // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
      var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
      var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
      var tempList = [];
  
      if(maxNum != 0 ) {
          for (var i = start; i <= end; i++) {
              tempList.push(i);
          }
      }
      setPaging(tempList);
  }
  const onClickPaging = (e) => {
    setPageNum(parseInt(e.target.text));
  
    return null; 
  }

  const getEnterReq = () => {
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/req/getEnterReq`,
      params : {    cmptLId: cmptLId
                    // , cmptGameId : cmptGameId
                    , mdtrGameOptnYn : "Y"
                    , reqUserId : getCookieUserId()
                }
      }
    fn_axios(param,callbackGetEnterReq,null);
}
  
const callbackGetEnterReq = (res) => {
  setEnterReqList(res.data.object);
}
const deleteReq = (cmptGameId,enterReqId) => {
  const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/req/deleteEnterReq`,
    params : {    cmptGameId : cmptGameId
                  , cmptLId: cmptLId
                  , enterReqId : enterReqId
                  , reqUserId : getCookieUserId()
              }
    }
  fn_axios(param,callbackDeleteReq,null);
}
  
const callbackDeleteReq = (res) => {
  console.log(res);
  setEnterReqList(res.data.object);
  getCmptGamePaging(pageNum); 
}

const saveEnterReq = (cmptGameId,cmptGameReqsYn, cmptGameReqsCompletYn,enterReqId) => {
  if(cmptGameReqsCompletYn == "Y") {
    alert("신청 완료한 세부종목은 선택 할 수 없습니다.");
    return ;
  }
  if(cmptGameReqsYn === "Y") {
    deleteReq(cmptGameId,enterReqId);
    return false;
  } else {
    const param = {
      
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/req/createEnterReq`,
      params : {    cmptGameId : cmptGameId
                    , cmptLId: cmptLId
                    , reqUserId : getCookieUserId()
                    , userId : getCookieUserId()
                    , mdtrGameOptnYn : "Y"
                }
      }
      fn_axios(param,callbackSaveEnterReq,null);
  }
}
  
const callbackSaveEnterReq = (res) => {
  if(res.data.status) {
    setEnterReqList(res.data.object);
    getCmptGamePaging(pageNum); 
  } else {
    alert(res.data.message);
  }
  
}

const openPartnerPopup = (propCmptGameId, propEnterReqId) => {
  setPopup(<PartnerSelector propCmpt={cmpt} propCmptGameId={propCmptGameId} propEnterReqId={propEnterReqId} onSelectedValuesChange={callbackOpenPartnerPopup}></PartnerSelector>);
}
const openTeamPopup = (propCmptGameId, propEnterReqId,enterTeamNo) => {
  setPopup(<TeamSelector propCmpt={cmpt} propCmptGameId={propCmptGameId} propEnterReqId={propEnterReqId} propTeamNo={enterTeamNo} onSelectedValuesChange={callbackOpenPartnerPopup}></TeamSelector>);
}

const callbackOpenPartnerPopup = () =>{
  setPopup(null);
  getEnterReq();
}
const moveReqPay = () => {

  if(EnterReqList == null || EnterReqList.length == 0) {
    alert("선택된 경기 종목이 없습니다.");
    return;
  }
  var isValid = true;
  EnterReqList.forEach(item => {
    // if(item.teamYn == 'Y' && item.enterTeamNm == null) {
    //   isValid= false;
    // } else 
    if (item.prtnYn == "Y" && item.prtnUserNm == null ) {
      isValid= false;
    }
  });
  if(isValid) {
    window.location.href = `/req/cmptReqsPay?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}&cmptLId=${cmptLId}`;
  } else {
    alert("파트너 선택이 완료되지 않았습니다.");
    // alert("파트너 혹은 팀 선택이 완료되지 않았습니다.");
    return false;
  }
  
}

  useEffect(() => {
      getCmptGamePaging(pageNum);
  }, [pageNum]); 

  useEffect(() => {
      if(pageNum == 1) {
          getCmptGamePaging(pageNum); 
      } else {
          setPageNum(1);
      }
  }, [cmptGameDivs1,cmptGameDivs2,cmptGameDivs3]); 

  useEffect(() => {
    if(cmpt == null) {
      getCmpt();
      getEnterReq();
    }
    if(cmptGameDivsMList == null || cmptGameDivsMList.length == 0) {
        getCmptGameDivs();
    }
      
  }, []); 


  return (
  <Layout>
  <div className="contents contest">

    <div className="top_line">
      <h1 className="sub_title pc">{cmpt == null ? null : cmpt.gameKindNm}대회</h1>
      <h1 className="sub_title mo">대회참가 / {cmpt == null ? null : cmpt.gameKindNm}</h1>
      <a href="#" className="btn_return pc">
        목록으로 돌아가기
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <section className="application">
      {/* <!-- menu tab & poster --> */}

      {/* <!-- mo ver title --> */}
      <h2 className="mo"><span>{cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
            </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
      {/* <!-- //mo ver title --> */}

      <div className="sub_menu">
        <div className="tab">
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >대회요강</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=game&gameKindNm="+gameKindNm} >세부종목</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=notice&gameKindNm="+gameKindNm} >알림/문의</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=item&gameKindNm="+gameKindNm} >경품/기념품</a>
        </div>
        <div className="tit">참가신청하기</div>
        <div className="poster">
        <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
        </div>
      </div>
      {/* <!-- //menu tab & poster /--> */}

      {/* <!-- content --> */}
      <div className="application_content">
        {/* <!-- pc ver title --> */}
        <div className="title pc"> 
          <h2><span>
          {cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
          </span>
          &nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
          <div className="tx_type04">
            <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
            <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
            <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </div>
        </div>
        {/* <!-- //pc ver title --> */}

        {/* <!-- content info --> */}
        <div className="info step1">
          {/* <!-- select --> */}
          <div className="search_box">
            <div className="tit">
              <b>Step1. </b><b>세부종목 선택 . </b><span>중복선택 가능</span>
            </div>
            <div>
              {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0)  ? null : 
              <select value={cmptGameDivs1 == null ? "" : cmptGameDivs1} onChange={handleCmptGameDivs1}>
              <option value={""}>{cmptGameDivsList1[0].gameDivsNm}</option>
              {cmptGameDivsList1.map((item, index) => (
                  <option key={item.gameDivsLId} value={item.gameDivsLId}>{item.gameDivsLNm}</option>
              ))}
              </select>
              }
              {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
              { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                || (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
              ? null : 
              <select name={cmptGameDivs2 == null ? "" : cmptGameDivs2} onChange={handleCmptGameDivs2}>
              <option value={""}>{cmptGameDivsList2[0].gameDivsNm}</option>
              {cmptGameDivsList2.map((item, index) => (
                  <option key={item.gameDivsLId} value={item.gameDivsLId}>{item.gameDivsLNm}</option>
              ))}
              </select>
              }
              {(cmptGameDivsList3 == null || cmptGameDivsList3.length == 0)  ? null : 
              <select name={cmptGameDivs3 == null ? "" : cmptGameDivs3} onChange={handleCmptGameDivs3}>
              <option value={""}>{cmptGameDivsList3[0].gameDivsNm}</option>
              {cmptGameDivsList3.map((item, index) => (
                  <option key={item.gameDivsLId} value={item.gameDivsLId}>{item.gameDivsLNm}</option>
              ))}
              </select>
              }
            </div>
          </div>
          {/* <!-- //select --> */}
          {/* <!-- table --> */}
          <div className="table_type1 default">
            <table>
              <colgroup className="pc">
                <col style={{width: "11%"}}/>
                <col style={{width:"auto"}}/>
                {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0)  ? null : 
                <col style={{width: "auto"}}/>
                }
                {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
              { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                || (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
                ? null : 
                <col style={{width: "auto"}}/>
                }
                {(cmptGameDivsList3 == null || cmptGameDivsList3.length == 0)  ? null : 
                <col style={{width: "auto"}}/>
                }
                <col style={{width: "10%"}}/>
              </colgroup>
              <colgroup className="mo">
                <col style={{width:"40%"}}/>
                {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0)  ? null : 
                <col style={{width: "auto"}}/>
                }
                {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
              { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                || (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
                ? null : 
                <col style={{width: "auto"}}/>
                }
                {(cmptGameDivsList3 == null || cmptGameDivsList3.length == 0)  ? null : 
                <col style={{width: "auto"}}/>
                }
                <col style={{width: "15%"}}/>
              </colgroup>
              <thead>
                    <th>No.</th>
                    <th>세부종목명</th>
                    {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0) ? null : 
                    <th >{cmptGameDivsList1[0].gameDivsNm}</th>
                    }
                    {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
                    { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                    || (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
                    ? null : 
                    <th>{cmptGameDivsList2[0].gameDivsNm}</th>
                    }
                    {(cmptGameDivsList3 == null || cmptGameDivsList1.length == 0)   ? null : 
                    <th>{cmptGameDivsList3[0].gameDivsNm}</th>
                    }
                    {/* <th>신청인원</th> */}
                    <th>선택</th>
                </thead>
                <tbody>
                    {(cmptGameList == null || cmptGameList.length == 0 ) ? 
                    <tr>
                        <td></td>
                        <td colSpan={cmptGameDivsList1 == null || cmptGameDivsList1.length == 0 ? 2 : 
                                     cmptGameDivsList2 == null || cmptGameDivsList2.length == 0 ? 3 : 
                                     cmptGameDivsList3 == null || cmptGameDivsList3.length == 0 ? 4 : 5}
                                    >검색 조건에 해당하는 세부종목이 없습니다. </td>
                    </tr>
                    : cmptGameList.map((item, index) => (
                        <tr key={item.no}>
                        <td>{item.no}</td>
                        <td>{item.gameLNm}</td>
                        {item.gameDivsLValue0 == null ? null : <td>{item.gameDivsLValue0}</td>}
                        {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
                        { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                          ||  (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
                        ? null :
                        <td>{item.gameDivsLValue1}</td>}
                        {item.gameDivsLValue2 == null ? null : <td>{item.gameDivsLValue2}</td>}
                        {/* <td>{item.enterPersonNum}/{item.allPersonNum}</td> */}
                        <td><input type="checkbox" name="apply_check" readOnly checked={item.cmptGameReqsYn == 'Y' ? true : false} onClick={() => saveEnterReq(item.cmptGameId, item.cmptGameReqsYn, item.cmptGameReqsCompletYn,  item.enterReqId)} /></td>
                        </tr>
                    ))}
                </tbody>
              </table>
          </div>
          {/* <!-- //table --> */}
          {/* <!-- paging --> */}
          {cmptGameList == null ? null : 
            <div className="paging">
                {paging.map((item, index) => (
                    <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                ))}
            </div>
            } 
          {/* <!-- //paging --> */}

          {/* <!-- step2 --> */}
          <div className="check_area step2">
            <div className="tit">Step2. 선택종목 확인</div>
            {/* <!-- table --> */}
            <div className="table_type2">
              <table>
                <colgroup>
                  <col style={{width:"60%"}}/>
                  {/* {(cmptGameDivsList1 == null || cmptGameDivsList1.length == 0) ? null : 
                  <col style={{width:"auto"}}/>
                  } */}
                  {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
                  {/* { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                    ||  (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
                    ? null :
                  <col style={{width:"auto"}}/>
                  }
                  {(cmptGameDivsList3 == null || cmptGameDivsList1.length == 0)   ? null : 
                  <col style={{width:"auto"}}/>
                  } */}
                  <col style={{width:"17%"}}/>
                  <col style={{width:"26%"}}/>
                  <col style={{width: "7% "}}/>
                </colgroup>
                <thead>
                  <tr>
                    <th colSpan={5}></th>
                  </tr>
                </thead>
                <tbody >
                {EnterReqList == null ? null : EnterReqList.map((item, index) => (
                  <tr key={index + "req"}>
                    <td>{item.gameLNm}</td>
                    {/* {item.gameDivsLValue0 == null ? null : <td>{item.gameDivsLValue0}</td>} */}
                    {/* 배드민턴의 경우 연령 임시로 안보이게 설정  */}
                    {/* { (cmptGameDivsList2 == null || cmptGameDivsList2.length == 0) 
                    ||  (cmpt != null && cmpt.gameKindCd == "GAME_KIND_0020" && cmptGameDivsList2[0].gameClsfKindCd == 'GAME_CLSF_KIND_CD_01')
                    ? null : <td>{item.gameDivsLValue1}</td>}
                    {item.gameDivsLValue2 == null ? null : <td>{item.gameDivsLValue2}</td>} */}
                    {item.teamYn == 'N' ? <td> </td> :  <td><button className="btn_type1 popup_open" onClick={() => openTeamPopup(item.cmptGameId, item.enterReqId,item.enterTeamNo)}>{item.enterTeamNm == null ? "팀 선택" : item.enterTeamNm}</button></td>}
                    {item.prtnYn != "Y" ?   <td> </td>  : <td><button className="btn_type1 popup_open" onClick={() => openPartnerPopup(item.cmptGameId, item.enterReqId)}>{item.prtnUserNm == null ? "파트너선택" : item.prtnUserNm.slice(0, -1) + ' * '}</button></td>}
                    <td className="del">
                      <button className="btn_type1" onClick={() => deleteReq(item.cmptGameId,item.enterReqId)}>-</button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
            {/* <!-- //table --> */}
          </div>
          {/* <!-- //step2 --> */}

          <button className="btn_application" onClick={moveReqPay}>참가신청 <br/> 접수하기</button>

        </div>
        {/* <!-- //content info --> */}
        {popup}
      </div>
      {/* <!-- //content --> */}
    </section>

  </div>
  </Layout>
  );
}
export default CmptReqs;