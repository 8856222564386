import React, { useEffect, useState } from "react";

function AdminHeader() {

    return (
        <header className="admin_header">
            <div className="header-left">
            </div>
            <div className="header-right">
                <img className="profile default" />
            </div>
        </header>
    )
}

export default AdminHeader;