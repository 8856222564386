import React, { useState, useEffect } from 'react';
import { fn_axios } from './common.tsx';
import { API } from '../config/config.tsx';



interface LareaAddrProviderProps {
  onSelectedLareaAddr: (selectedValues: any) => void;
  userSelectLareaAddr: any;
}

const LareaAddr : React.FC<LareaAddrProviderProps> = ({ onSelectedLareaAddr, userSelectLareaAddr })=> {
  const [sidoList, setSidoList] = useState([]);
  const [signguList, setSignguList] = useState([]);
  const [signguDataList, setSignguDataList] = useState([]);
  const [selectedSido, setSelectedSido] = useState('');
  const [selectedSigngu, setSelectedSigngu] = useState('');
  const [selectedResult, setSelectedResult] = useState([]);

  const [isInit, setIsInit] = useState<boolean>(true); //추가


  const getLareaAddr = () => {
    const param = {
        method: 'GET',
        url: `${API.BACKEND}/v1/lareaAddr/getLareaAddr`,
        params: null,
      };
      fn_axios(param, callBackGetLareaAddr, null);
  }

  const initValue = () => {
    if(userSelectLareaAddr != null && userSelectLareaAddr.length > 0 && selectedSigngu === '') {
      // for(var i = 0; i < signguDataList.length ; i++ ) {
      //   if(signguDataList[i].lareaAddrId == userSelectLareaAddr) {
      //     setSelectedSido(signguDataList[i].spSignguCd);
      //     setSignguList(signguDataList.filter( item => item.spSignguCd === signguDataList[i].spSignguCd && (item.signguNm || '').trim() !== "" ));
      //   }
      // }
      setSelectedResult(userSelectLareaAddr);
    }
  }


  const callBackGetLareaAddr = (res) => {  
    const resList = res.data.object;
    const tempList =resList.filter(item => (item.signguNm || '').trim() === "");

    setSignguDataList(resList);
    // setSidoList(tempList.map(item => item.spSignguNm));
    setSidoList(tempList);
  }


  useEffect(() => {
    // 전체 시/도 목록 가져오기
    getLareaAddr();
  }, []);

  useEffect(() => {
    if(isInit && sidoList.length > 0) {
      setIsInit(false);
      initValue();
    }
  }, [sidoList]);


  const handleSidoChange = (e) => {
    // console.log(e.target.id);
    setSelectedSido(e.target.value);
    setSignguList(signguDataList.filter( item => item.spSignguCd === e.target.value && (item.signguNm || '').trim() !== "" ));
    return;
  };


  const handleSignguChange  = (e) => {
    console.log(e);
    setSelectedSigngu(e.target.value);
    // onSelectedLareaAddr(e.target.value);
  }

  const selectSido = () => {
    console.log(selectedSigngu);

    if(selectedSigngu == "") {
      alert("시군구 선택 후 클릭해주세요.");
      return;
    }
    if(selectedResult.length > 4) {
      alert("최대 5개까지만 선택 가능합니다.");
      return;
    }
    
    for(let i = 0 ; i < selectedResult.length ; i++) {
      if(selectedResult[i] == selectedSigngu) {
        alert("이미 선택된 선호 지역입니다.");
        return;
      }
    }
    setSelectedResult(selectedResult.concat(selectedSigngu));
    setSelectedSido("");
    setSelectedSigngu("");
    onSelectedLareaAddr(selectedResult.concat(selectedSigngu));
  }

  const deleteResult = (result) => {
    setSelectedResult(selectedResult.filter( item => item !== result ));
    onSelectedLareaAddr(selectedResult.filter( item => item !== result) );
  }


  return (
    <div>
      <div className="area" style={{margin:"5px"}}>
        <span>관심지역 1개 이상 5개 이하 필수선택</span>
      </div>
      <div className="area" style={{margin:"5px"}}>
          <select name="area1" id="sido1" value={selectedSido} onChange={handleSidoChange}>
            <option value="">전체</option>
            {sidoList.map((item, index) => (
              <option key={index} value={item.spSignguCd} >
                {item.spSignguNm}
              </option>
            ))}
          </select>
          <select name="area2" id="signgu1" value={selectedSigngu} onChange={handleSignguChange}  >
            <option value="" >전체</option>
            {signguList.map((item, index) => (
              <option key={index} value={item.lareaAddrId} >
                {item.signguNm}
              </option>
            ))}
          </select>
          <label onClick={selectSido} style={{background: "#111", color: "#fff", fontSize: "1.4rem", fontWeight: "500", letterSpacing: "-0.03em", borderRadius: "5px", display: "inline-flex", justifyContent: "center", alignItems: "center", minWidth: "27px", height: "27px", marginLeft: "2px", padding: "2px",fontFamily:"Noto Sans KR"}}>+</label>
      </div>
      <div style={{display:"block"}}>
        {selectedResult.map((item, index) => (
          <div style={{margin:"5px"}}>
            <label style={{background: "gray", color: "#fff", fontSize: "1.4rem", fontWeight: "500", letterSpacing: "-0.03em", borderRadius: "5px", display: "inline-flex", justifyContent: "center", alignItems: "center", minWidth: "27px", height: "27px", marginLeft: "2px", padding: "2px",fontFamily:"Noto Sans KR"}}>
              {signguDataList.map((signgu, index) => (
                item != signgu.lareaAddrId ? "" : signgu.spSignguNm + " " +  signgu.signguNm
              ))}
            </label>
            <label onClick={() => deleteResult(item)}style={{background: "#111", color: "#fff", fontSize: "1.4rem", fontWeight: "500", letterSpacing: "-0.03em", borderRadius: "5px", display: "inline-flex", justifyContent: "center", alignItems: "center", minWidth: "22px", height: "27px", marginLeft: "1px", padding: "2px",fontFamily:"Noto Sans KR"}}>-</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LareaAddr;