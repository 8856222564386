export const API = {
	BACKEND:process.env.REACT_APP_BACKEND_API_IP,
	FRONTEND:process.env.REACT_APP_FRONTEND_API_IP,
	DOMAIN:process.env.REACT_APP_FRONTEND_API_DOAMIN,
	KAKAO_REDIRECT_URL:process.env.REACT_APP_KAKAO_REDIRECT_URL,
	NAVER_REDIRECT_URL:process.env.REACT_APP_NAVER_REDIRECT_URL,
	VERSION:"v1"
};
export const KAKAO_REST_APP_KEY = process.env.REACT_APP_KAKAO_REST_KEY;
export const KAKAO_AUTH_URL = `https://kauth.kakao.com/oauth/authorize?client_id=${KAKAO_REST_APP_KEY}&redirect_uri=${API.KAKAO_REDIRECT_URL}&response_type=code`;

export const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;
export const NAVER_CLIENT_SECRET = process.env.REACT_APP_NAVER_CLIENT_SECRET;
export const NAVER_AUTH_URL = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${NAVER_CLIENT_ID}&redirect_uri=${API.NAVER_REDIRECT_URL}&state=RAMDOM_STATE`;