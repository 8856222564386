import React from "react";
import moment from "moment";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import Layout from "../../layout/Layout.tsx";
import {fn_axios,getCookieUserId,compareToday} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';

function UserPayDetail() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const payId  = queryParams.get('payId');
  const cpmtLNm  = queryParams.get('cpmtLNm');
  const gameKindNm  = queryParams.get('gameKindNm');
  const [cmpt, setCmpt] = useState(null);
  const [cmptGym, setCmptGym] = useState([]);
  const [pgPay, setPgPay] = useState(null);
  const [cnclPay, setCnclPay] = useState(null);
  const [user, setUser] = useState(null);
  const [EnterReqList, setEnterReqList] = useState([]);
  const [fare, setFare] = useState(0);
  
  const [confirmPoint, setConfirmPoint] = useState(0);


  const getPayCnclReq = () => {
    if(pgPay == null ) {
      return ;
    }
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/pgPay/getPayCnclReq`,
        params: {payId : pgPay == null ? null : pgPay.payId},
      };
      fn_axios(param, callbackgetPayCnclReq, null);
  }
  const callbackgetPayCnclReq = (res) => {  
    setCnclPay(res.data.object);
  }
  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getCmpt`,
        params: {cmptLId : cmptLId},
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  
    // setSignguDataList(resList);
    setCmpt(res.data.map.cmpt[0]);
    setCmptGym(res.data.map.cmptGym);
  }
  const getUser = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/user/getUser`,
        params: {userId : getCookieUserId()},
      };
      fn_axios(param, callbackGetUser, null);
  }
  const callbackGetUser = (res) => {  
    // setSignguDataList(resList);
    setUser(res.data.object);
    
  }

  const getEnterReq = () => {
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/req/getEnterReq`,
      params : {    cmptLId: cmptLId
                    // , cmptGameId : cmptGameId
                    , payId : payId
                    , reqUserId : getCookieUserId()
                }
      }
    fn_axios(param,callbackGetEnterReq,null);
  }
    
  const callbackGetEnterReq = (res) => {
    setEnterReqList(res.data.object);
    setPgPay(res.data.map.pgPayVo);
    
    var list = res.data.object;
    var tempFare = 0;
    for(var i = 0 ; i < list.length ; i++ ) {
      tempFare += list[i].enterFare;
    }
    setFare(tempFare);
  }

  const cancelCard = () => {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/kiwoompay/cancelCard`,
      params : {    cmptLId: cmptLId
                    // , cmptGameId : cmptGameId
                    , payId : payId
                    , reqUserId : getCookieUserId()
                    , PAYMETHOD : 'CARD'
                    , AMOUNT : pgPay == null ? null : pgPay.payAmt
                    , TRXID : pgPay == null ? null : pgPay.daoutrx
                    , CANCELREASON : "TEST"
                    , usePoint : pgPay == null ? 0 : pgPay.usePoint
                }
      }
    fn_axios(param,callbackCancelCard,null);
  }
    
  const callbackCancelCard = (res) => {
    if(res.data.status) {
      alert(res.data.message);
      getPayCnclReq();
    } else {
      alert(res.data.message);
    }
  }

  const getCheckCancel = (date) => {
    const today = new Date(date);
    const newDay = new Date();
    newDay.setDate(today.getDate() - 7)
    // return newDay > new Date();
    return true; // TEST
  }
  const clickUpdateGameDivs = () => {

    if(cnclPay) {
      alert("취소 신청된 신청 내역은 수정이 불가능합니다.");
      return 
    }
    if(cmpt != null && compareToday(cmpt.cmptReqEndDtm)) {
      alert("신청 마감일 전까지만 수정 가능합니다. \n수정이 필요한 경우 대회 관리자에게 문의 바랍니다.");
      return 
    }
    window.location.href="/user/userReqCmptUpdate?cmptLId=" + cmptLId + "&payId=" + payId + "&activeTab=main&gameKindNm="+gameKindNm;
  }
  useEffect(() => {
    if(cmpt == null) {
      getUser();
      getCmpt();
      getEnterReq();
    }
  }, []); 
  useEffect(() => {
    getPayCnclReq();
  }, [pgPay]); 

  return (
    <Layout>
    <div className="contents contest">
      <div className="top_line">
          <h1 className="sub_title pc" onClick={() => window.location.href = `/user/userPayList`}> 결제내역</h1>
          <h1 className="sub_title mo" onClick={() => window.location.href = `/user/userPayList`}> 결제내역</h1>
          <a href="#" onClick={() => window.location.href = `/user/userPayList`} className="btn_return pc">
            목록으로 돌아가기
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
  
        <section className="application payment">
          {/* <!-- menu tab & poster --> */}
  
          {/* <!-- mo ver title --> */}
          <h2 className="mo"><span>{cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
                </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
          {/* <!-- //mo ver title --> */}
  
          <div className="sub_menu">
            <div className="tab">
            <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >대회요강</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=game&gameKindNm="+gameKindNm} >세부종목</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=notice&gameKindNm="+gameKindNm} >알림/문의</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=item&gameKindNm="+gameKindNm} >경품/기념품</a>
            </div>
            <div className="tit">결제 상세</div>
            <div className="poster">
            <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
            </div>
          </div>
          {/* <!-- //menu tab & poster --> */}
  
          {/* <!-- content --> */}
          <div className="application_content">
            {/* <!-- pc ver title --> */}
            <div className="title pc"> 
              <h2><span>
              {cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
              </span>
              &nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
              <div className="tx_type04">
                <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
                <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
                <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
              </div>
            </div>
            {/* <!-- //pc ver title --> */}
  
            {/* <!-- content info --> */}
            <div className="info step3">
              {/* <!-- select --> */}
              <div className="search_box">
                <div className="tit">
                  <b>결제 내역 확인</b>
                </div>
              </div>
              {/* <!-- //select --> */}
              
              <div className="list">
                <ul>
                  <li>
                    <span className="tit">대회명</span>
                    <span><b>{cmpt == null ? null : cmpt.cmptLNm}</b></span>
                  </li>
                  <li>
                    <span className="tit">기간</span>
                    <span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD')+ " ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD')}</span>
                  </li>
                  <li>
                    <span className="tit">개최장소</span>
                    {cmptGym == null ?  null : cmptGym.map((item, index) => (
                    <span  style={{cursor:"POINTER",textDecoration:"underline"}}onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}`}>{item.gymNm }</span>
                    ))}
                  </li>
                </ul>
                <ul>
                  <li>
                    <span className="tit">이름</span>
                    <span><b>{user == null ? null : user.userNm}</b></span>
                  </li>
                  <li>
                    <span className="tit">생년월일</span>
                    <span>{user == null ? null : user.userBirth.substring(0,4) + "년 " + user.userBirth.substring(4,6) + "월 " + user.userBirth.substring(6,8) +"일"}</span>
                  </li>
                  <li>
                    <span className="tit">주소</span>
                    <span>{user == null ? null : user.userAddr + " " + user.userDtlAddr}</span>
                  </li>
                  <li>
                    <span className="tit">전화번호</span>
                    <span>{user == null ? null : user.userPhone.substring(0,3) + "-" + user.userPhone.substring(3,7) + "-" + user.userPhone.substring(7,12)}</span>
                  </li>
                  <li>
                    <span className="tit">이메일</span>
                    <span>{user == null ? null : user.userEmail}</span>
                  </li>
                  <li className="details">
                    <span className="tit">세부종목</span>
                    <div className="detail_box">
                      <span className="tit mo">세부종목</span>
                      {EnterReqList == null ? null : EnterReqList.map((item, index) => (
                      <div  key={index + "req"}>
                        <div>
                          <p className="first_line">
                          {item.gameDivsLValue0 == null || item.gameClsfKindCd0 == 'GAME_CLSF_KIND_CD_05' ? null : <span>{item.gameDivsNm0 + " : " + item.gameDivsLValue0}</span>}
                          {item.gameDivsLValue1 == null || item.gameClsfKindCd1 == 'GAME_CLSF_KIND_CD_05' ? null : <span>{item.gameDivsNm1 + " : " + item.gameDivsLValue1}</span>}
                          {/* {item.gameDivsLValue2 == null ? null : <span>{item.gameDivsLValue2}</span>} */}
                            <span className="pay1">금액 : {item.enterFare}</span>
                          </p>
                          <p className="tx_type05">{item.teamYn == 'N' ? "" :  " 팀 : " + (item.enterTeamNm  == null ? '팀없음' : item.enterTeamNm ) + " / "}&nbsp;&nbsp;&nbsp;{item.prtnYn != "Y" ?   ""  : "파트너 : " + item.prtnUserNm.slice(0, -1) + ' * '}</p>
                        </div>
                        {item.gameDivsLValue1 == null || item.gameClsfKindCd1 != 'GAME_CLSF_KIND_CD_05' ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm1 + " : "}</b> <span>{item.cmptReqGameDivsValue1}</span></div>}
                        {item.gameDivsLValue2 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm2 + " : "}</b> <span>{item.cmptReqGameDivsValue2 || item.gameDivsLValue2}</span></div>}
                        {item.gameDivsLValue3 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm3 + " : "}</b> <span>{item.cmptReqGameDivsValue3 || item.gameDivsLValue3}</span></div>}
                        {item.gameDivsLValue4 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm4 + " : "}</b> <span>{item.cmptReqGameDivsValue4 || item.gameDivsLValue4}</span></div>}
                        {item.gameDivsLValue5 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm5 + " : "}</b> <span>{item.cmptReqGameDivsValue5 || item.gameDivsLValue5}</span></div>}
                        {item.gameDivsLValue6 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm6 + " : "}</b> <span>{item.cmptReqGameDivsValue6 || item.gameDivsLValue6}</span></div>}
                        {item.gameDivsLValue7 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm7 + " : "}</b> <span>{item.cmptReqGameDivsValue7 || item.gameDivsLValue7}</span></div>}
                        {item.gameDivsLValue8 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm8 + " : "}</b> <span>{item.cmptReqGameDivsValue8 || item.gameDivsLValue8}</span></div>}
                        {item.gameDivsLValue9 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm9 + " : "}</b> <span>{item.cmptReqGameDivsValue9 || item.gameDivsLValue9}</span></div>}
                      </div>
                    ))}
                      <div style={{alignContent:"right"}}>
                        <button className="btn_type3" onClick={clickUpdateGameDivs}>
                          세부종목 수정
                        </button>
                      </div>
                    </div>
                  </li>
                  <li className="total">
                    <span className="tit">결제금액</span>
                    <span className="pay2">{fare.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                  </li>
                  <li className="deduct">
                    <span className="tit">사용 포인트</span> <span>{pgPay == null ? 0 : pgPay.usePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} point</span>
                  </li>
                  <li className="total">
                    <span className="tit tx_type06">최종결제금액</span>
                    <span className="pay3">{pgPay == null ? 0 : (pgPay.payAmt).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                  </li>
                  {cnclPay == null ? null :
                    <li className="total">
                      <span className="tit tx_type06">취소 승인 일자</span>
                      <span >{moment(cnclPay.canceldate).format('YYYY-MM-DD HH:mm')}</span>
                    </li>
                  }
                </ul>
              </div>
            </div>
            {/* <!-- //content info --> */}
            {     
                  cnclPay != null ?  
                  <div>
                    <div className="tit" style={{textAlign:"center",marginTop:"10px", fontWeight: "500",fontSize:"1.4rem"}}><b>결제 취소 신청이 완료되었습니다.</b></div>
                  </div>
                  : 
                    (cmpt == null ? null :
                      compareToday(cmpt.cmptReqEndDtm) ?  
                          <div>
                            <div className="tit" style={{textAlign:"center",marginTop:"10px", fontWeight: "500",fontSize:"1.4rem"}}><b>결제 취소 신청 기간이 지났습니다.</b></div>
                          </div>
                        :
                          getCheckCancel(cmpt.cmptReqStrDtm) ? 
                            <div>
                              <button className="btn_application okay" onClick={cancelCard}>결제 취소</button>
                              <div className="tit" style={{textAlign:"center",marginTop:"10px", fontWeight: "500",fontSize:"1.4rem"}}><b>취소는 접수 마감일 7일 전까지만 가능합니다.</b></div>
                            </div>
                            // <div>
                            //   <div className="tit" style={{textAlign:"center",marginTop:"10px", fontWeight: "500",fontSize:"1.4rem"}}><b>정식 오픈 전까지는 결제 취소는 카카오채널로 문의바랍니다.</b></div>
                            // </div>
                            : 
                            null)
            }
          {/* <!-- //content --> */}
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default UserPayDetail;