import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { fn_axios, fn_axios_post_excel_down, getCookieUserId, CONSTANTS } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';
import AdminLyout from '../../layout/admin/AdminLayout.tsx';

function UserManage() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [userMngList, setUserMngList] = useState([]);
    const [pageNum, setPageNum] = useState(() => {
        const page = queryParams.get('pageNum');
        return page ? parseInt(page) : 1;
    });
    const [pageMaxNum, setPageMaxNum] = useState(0);
    const [pageCnt] = useState(10);
    const [paging, setPaging] = useState([]);
    const [usrMngCnt, setUsrMngCnt] = useState(null);
    const [headerList, setHeaderList] = useState([]);
    const [user, setUser] = useState({ kakaoId: null, naverId: null });

    // modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    // search
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selecctedOrdColumn, setSelectedOrdColumn] = useState('');
    const [selectedLogInfo, setSelectedLogInfo] = useState('');

    useEffect(() => {

    }, []);

    useEffect(() => {
        getUserManagePaging(pageNum);
    }, [pageNum]);

    const getUserManagePaging = async (num: number) => {
        const params = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/mng/getUserManagePaging`,
            params: {
                pageNum: num,
                searchKeyword: searchKeyword,
                LogInfo: selectedLogInfo,
                ordColumn: selecctedOrdColumn
            }
        };
        fn_axios(params, callbackGetUserManagePaging, null);
    };

    const onClickSearch = () => {
        if (pageNum == 1) {
            getUserManagePaging(pageNum);
        } else {
            setPageNum(1);
        }
    };

    const handleSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
    };
    const handleOrdColumn = (e) => {
        setSelectedOrdColumn(e.target.value);
    };
    const handleLogInfo = (e) => {
        setSelectedLogInfo(e.target.value);
    }

    const callbackGetUserManagePaging = (res) => {
        setUserMngList(res.data.object);
        setUsrMngCnt(res.data.map.usrMngCnt);
        console.log(res);
        console.log(setUsrMngCnt);
        var tempInt = parseInt(res.data.map.usrMngCnt % pageCnt);
        if (tempInt != 0) {
            tempInt = parseInt(res.data.map.usrMngCnt / pageCnt) + 1;
        } else {
            tempInt = parseInt(res.data.map.usrMngCnt / pageCnt);
        }
        settingPasing(tempInt);
    }

    const settingPasing = (maxNum) => {
        setPageMaxNum(parseInt(maxNum));
        // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
        var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2);
        var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) + 2;
        var tempList = [];

        if (maxNum != 0) {
            for (var i = start; i <= end; i++) {
                tempList.push(i);
            }
        }
        setPaging(tempList);
    }
    const onClickPaging = (e) => {
        setPageNum(parseInt(e.target.text));
        //getPointPage(e.target.text);

        return null;
    }

    const openModal = (userEmail) => {
        setSelectedUser(userEmail);
        setIsModalOpen(true);
        console.log(openModal);
    };
    const closeModal = () => {
        setSelectedUser(null);
        setIsModalOpen(false);
    }

    const settingHeaderList = (obj) => {
        if (obj != null && obj.length > 0) {
            var tempList = [
                "no",
                "이름",
                "이메일",
                "전화번호",
                "성별",
                "소셜정보",
                "마지막 로그인 일자"
            ];
            if (obj[0].userDivsNm0 != null && obj[0].userDivsNm0 != "") tempList = [...tempList, obj[0].userDivsNm0]
            if (obj[0].userDivsNm1 != null && obj[0].userDivsNm1 != "") tempList = [...tempList, obj[0].userDivsNm1]
            if (obj[0].userDivsNm2 != null && obj[0].userDivsNm2 != "") tempList = [...tempList, obj[0].userDivsNm2]
            if (obj[0].userDivsNm3 != null && obj[0].userDivsNm3 != "") tempList = [...tempList, obj[0].userDivsNm3]
            if (obj[0].userDivsNm4 != null && obj[0].userDivsNm4 != "") tempList = [...tempList, obj[0].userDivsNm4]
            if (obj[0].userDivsNm5 != null && obj[0].userDivsNm5 != "") tempList = [...tempList, obj[0].userDivsNm5]
            if (obj[0].userDivsNm6 != null && obj[0].userDivsNm6 != "") tempList = [...tempList, obj[0].userDivsNm6]
            setHeaderList(tempList);
        }
    }

    const getUserManageExcelDown = () => {
        const param = {
            url: `${API.BACKEND}/${API.VERSION}/mng/getUserManageExcelDown`,
            params: {
                headerList: headerList
            }
        };
        fn_axios_post_excel_down(param, null, null);
    }

    const onClickExcelDown = () => {
        getUserManageExcelDown();
    }

    return (
        <AdminLyout>
            <div className="">
                <h1 className="sub_title2">사용자 관리</h1>
                <section className="ground_list list_type1">
                    <div className="bar">
                        <div className="tx_type03 odr2"> 총 <b>{usrMngCnt == null ? "0" : usrMngCnt}</b>명</div>
                        <div className="search_box odr1">
                            <select style={{ minWidth: "50px" }} value={selecctedOrdColumn} onChange={handleOrdColumn}>
                                <option value="">정렬</option>
                                <option key={1} value={'USER_ID'}>순번</option>
                                <option key={1} value={'USER_NM'}>이름</option>
                            </select>
                            <select style={{ minWidth: "50px" }} value={selectedLogInfo} onChange={handleLogInfo}>
                                <option value="">전체</option>
                                <option key={1} value={"일반"}>일반 회원</option>
                                <option key={1} value={'카카오'}>카카오 회원</option>
                                <option key={1} value={'네이버'}>네이버 회원</option>
                            </select>
                            <input type="text" style={{ minWidth: "90px" }} name="keyword" value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드" />
                            <button onClick={onClickSearch}>검색</button>
                            <button onClick={onClickExcelDown}>Excel Download</button>
                        </div>
                    </div>
                    <div className="table_type1" style={{ overflow: "auto" }}>
                        <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>이름</th>
                                    <th>이메일</th>
                                    <th>전화번호</th>
                                    <th>성별</th>
                                    <th>소셜정보</th>
                                    <th>마지막 로그인 일자</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userMngList.length === 0 ? (
                                    <tr>
                                        <td style={{ textAlign: "center" }} colSpan={7}>
                                            검색된 결과가 없습니다.
                                        </td>
                                    </tr>
                                ) : (
                                    userMngList.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.no}</td>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.userNm}</td>
                                            {/*<td>{item.userEmail}</td>*/}
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}><a onClick={() => openModal(item.userEmail)}>{item.userEmail}</a></td>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.userPhone}</td>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.userGndr}</td>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.codeLNm}</td>
                                            <td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.lastLoginDtm}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                        {/* 팝업 
                        {isModalOpen && (
                            <div className="modal">
                                <div className="modal-content">
                                    <span className="close" onClick={closeModal}>&times;</span>
                                    <h2>회원 상세 정보</h2>
                                    {selectedUser && (
                                        <div>
                                            <p>이름 : {selectedUser.userNm}</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                            */}

                    </div>
                    <div className="paging">
                        {paging.map(num => (
                            <a key={num} onClick={(onClickPaging) => setPageNum(num)}>{num}</a>
                        ))}
                    </div>
                </section>
            </div>
        </AdminLyout>
    );
}

export default UserManage;
