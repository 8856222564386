import React from "react";
import moment from "moment";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';


import {fn_axios,fn_post_fileUpload_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from "../../layout/Layout.tsx";

import KiwoomPay from "../../common/KiwoomPay.tsx";

function CmptReqsPay() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const gameKindNm = queryParams.get('gameKindNm');
  const [cmpt, setCmpt] = useState(null);
  const [cmptGym, setCmptGym] = useState([]);
  const [user, setUser] = useState(null);
  const [isPay, setIsPay] = useState(false);
  const [payParam, setPayParam] = useState({});
  const [EnterReqList, setEnterReqList] = useState([]);
  const [fare, setFare] = useState(0);
  const [usePoint, setUsePoint] = useState(0);
  const [confirmPoint, setConfirmPoint] = useState(0);
  const [payId, setPayId] = useState("");

  const [gameDivsValueMap, setGameDivsValueMap] = useState({});
  

  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getCmpt`,
        params: {cmptLId : cmptLId},
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  
    setCmpt(res.data.map.cmpt[0]);
    setCmptGym(res.data.map.cmptGym);
  }
  const getUser = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/user/getUser`,
        params: {userId : getCookieUserId()},
      };
      fn_axios(param, callbackGetUser, null);
  }
  const callbackGetUser = (res) => {  
    setUser(res.data.object);
  }

  const getEnterReq = () => {
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/req/getEnterReq`,
      params : {    cmptLId: cmptLId
                    // , cmptGameId : cmptGameId
                    , reqUserId : getCookieUserId()
                }
      }
    fn_axios(param,callbackGetEnterReq,null);
  }
    
  const callbackGetEnterReq = (res) => {
    // console.log(res);
    setEnterReqList(res.data.object);
    var list = res.data.object;
    var tempFare = 0;
    for(var i = 0 ; i < list.length ; i++ ) {
      tempFare += list[i].enterFare;
    }
    setFare(tempFare);
    setPayId(list[0].payId);
  }

  const handleUsePoint = (e) => {
    if(e.target.value > user.point) {
      alert("보유된 포인트 이상은 입력 불가능입니다.");
      return false;
    } else if(fare < e.target.value){
      alert("결제금액보다 높은 포인트는 사용 불가능합니다.");
      return false;
    } else {
      setUsePoint(e.target.value);
    }
    
  }

  const clickPoint = () => {
    setConfirmPoint(usePoint);
  }

  const createGameDvisValue = () => {
    let keyList = Object.keys(gameDivsValueMap);
    let tempList = [];
    if(cmpt != null && new Date(cmpt.cmptReqEndDtm) < new Date()) {
      alert("참가신청이 종료되었습니다");
      return false;
    } 
    // console.log(gameDivsValueMap);

    if(keyList != null && keyList.length > 0) {
      for(var i = 0 ; i < keyList.length ; i++ ) {
        if(gameDivsValueMap[keyList[i]] == "") {
          alert("값이 입력되지 않았습니다.");
          if(document.getElementById(keyList[i]) !=null) document.getElementById(keyList[i]).focus();
          return;
        } else {
          // console.log(gameDivsValueMap[keyList[i]]);
          // 키값에 | 가 있는경우 리스트임.
          if(keyList[i].split("|").length > 1) {
            tempList.push({"enterReqId":keyList[i].split("_")[0],"gameDivsLId":gameDivsValueMap[keyList[i]],"cmptReqGameDivsValue":"","userId":getCookieUserId()});
          } else {
            tempList.push({"enterReqId":keyList[i].split("_")[0],"gameDivsLId":keyList[i].split("_")[1],"cmptReqGameDivsValue":gameDivsValueMap[keyList[i]],"userId":getCookieUserId()});
          }
        }
      }
      // console.log(tempList);
      // return ;
  
      const param = {
        method : 'POST',
        url : `${API.BACKEND}/${API.VERSION}/req/createGameDvisVlalue`,
        params : {      gameDivsValueList : tempList
                  }
        }
        fn_axios(param,callbackCreateGameDvisValue,null);
    } else {
      createPgPay();
    }
  }

  const callbackCreateGameDvisValue = (res) => {
    if(res.data.status) {
      createPgPay();
    } else {
      alert(res.data.message);
    }
  }

  const createPgPay = () => {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/req/getCheckCmptGameDivs`,
      params : {      cmptLId: cmptLId
                    , reqUserId : getCookieUserId()
                    , userId : getCookieUserId()
                }
      }
      fn_axios(param,callbackCreatePgPay,null);
    
  }

  const callbackCreatePgPay = (res) => {
    if(res.data.status) {
      openPay()
    } else {
      alert(res.data.message);
    }
  }
  const cssGameDivInput = {
    background: "#fff" ,
    border: "1px solid #aaa",
    height: "28px",
    maxWidth: "130px",
    textAlign: "center",
    fontSize: "1.6rem",
    color: "#111",
    letterSpacing: "-0.01em"
  };
  const checkGameDivs = (gameClsfKindCd,gameDivsLValue,gameDivsLId , enterReqId, gameDivsNm) => {
    if(gameClsfKindCd  != null && (gameClsfKindCd == "GAME_CLSF_KIND_CD_03" || gameClsfKindCd == "GAME_CLSF_KIND_CD_04" || gameClsfKindCd == "GAME_CLSF_KIND_CD_05")) {
      const key = `${enterReqId}_${gameDivsLId}`;
      let options = [];
      let options2 = [];
      if(gameClsfKindCd == "GAME_CLSF_KIND_CD_04") {
        if(!gameDivsValueMap[key]){
          setGameDivsValueMap({ ...gameDivsValueMap, [key]: "N"})
        } 
      } else if(gameClsfKindCd == "GAME_CLSF_KIND_CD_05") {
        options = gameDivsLValue.split('|');
        options2 = gameDivsLId.split('|');
        if(!gameDivsValueMap[key]){
          setGameDivsValueMap({ ...gameDivsValueMap, [key]: options2[0]});
        }
      } else {
        if(!gameDivsValueMap[key]){
          setGameDivsValueMap({ ...gameDivsValueMap, [key]: ""})
        }
      }

      return (
        <div style={{ marginTop: "5px" }}>
          <b>{gameDivsNm + " "}</b>
          <span>
            {gameClsfKindCd === "GAME_CLSF_KIND_CD_03" && (
              <input
                maxLength={100}
                id={key}
                style={cssGameDivInput}
                value={gameDivsValueMap[key] || ""}
                onChange={(e) => setGameDivsValueMap({ ...gameDivsValueMap, [key]: e.target.value })}
              />
            )}
            {gameClsfKindCd === "GAME_CLSF_KIND_CD_04" && (
              <select
                style={{ textAlign: "center" }}
                id={key}
                value={gameDivsValueMap[key] || "N"}
                onChange={(e) => setGameDivsValueMap({ ...gameDivsValueMap, [key]: e.target.value })}
              >
                <option value="Y">Y</option>
                <option value="N">N</option>
              </select>
            )}
            {gameClsfKindCd === "GAME_CLSF_KIND_CD_05" && (
              <select
                style={{ textAlign: "center", width: "50%" }}
                id={key}
                value={gameDivsValueMap[key] || options[0]}
                onChange={(e) => setGameDivsValueMap({ ...gameDivsValueMap, [key]: e.target.value })}
              >
                {options.map((item, index) => (
                  <option key={index} value={options2[index]}>{item}</option>
                ))}
              </select>
            )}
          </span>
        </div>
      );
    } else {
      return ;
    }

  }

  const openPay = () => {
    var param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/pgPay/createCmptPgPay`,
      params : {    payItemCd : cmptLId
                    , payItemNm: cmpt == null ? "" : cmpt.cmptLNm
                    , payUserNm : user == null ? '' :  user.userNm
                    , userId : user == null ? '' :  user.userId
                    , usePoint : confirmPoint
                    , payAmt : (fare - confirmPoint)
                }
      }
      fn_axios(param,callbackOpenPay,null);
  }

  const callbackOpenPay = (res) => {
    console.log(res.data);
    
    if(res.data.status) {
      if(res.data.object.payAmt == 0 ) {
        window.location.href = `${API.FRONTEND}/req/cmptReqsPayComplet?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}&cmptLId=${cmptLId}&usePoint=${confirmPoint}&payId=${res.data.object.payId}&cpmtLNm=${cmpt == null ? null : cmpt.cpmtLNm}`;
      } else { 
        setPayId(res.data.object.payId);
        var param = {
          PAYMETHOD : "CARD", // 결제수단
          TYPE : 'P', // 결제방식(P:PC/ M:모바일 /W:웹뷰)
          CPID: process.env.REACT_APP_KIWOOMPAY_CPID,  //가맹점ID  (필수) : TEST(CTS18333)
          ORDERNO: res.data.object.payId, // 결제번호(우리그라운드에서 부여고객이 찾을때 사용) //임시로 1 부여
          AMOUNT: (fare - confirmPoint), // 결제금액
          PRODUCTNAME: cmpt == null ? "" : cmpt.cmptLNm, //상품명 
          EMAIL: user == null ? '' : user.email, // 결제 통보 이메일
          USERID: user == null ? '' : user.userId, // 고객ID (TB_USER_M.user_id)
          USERNAME: user == null ? '' : user.userNm, // 고객명 (TB_USER_M.user_nm)
          PRODUCTCODE: cmptLId, // 상품코드 : TB_CMPT_L.CMPT_L_ID 들어갈 예정. 추후 나중에 상품 추가되면 다른거 추가.
          HOMEURL: `${API.FRONTEND}/req/cmptReqsPayComplet?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}&cmptLId=${cmptLId}&usePoint=${confirmPoint}&payId=${res.data.object.payId}&cpmtLNm=${cmpt == null ? null : cmpt.cpmtLNm}`, //결제 성공 후, 이동할 URL (결제 창에서 이동)
          FAILURL : `${API.FRONTEND}/req/cmptReqsPayFail?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}&cmptLId=${cmptLId}`, // 결제 실패 후 , 확인 버튼 입력 시 이동할 URL 결제 창에서 이동    
          CLOSEURL : `${API.FRONTEND}/req/cmptReqsPay?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}&cmptLId=${cmptLId}`, //결제 창에서 취소 누를 시 이동할 URL 결제 창에서 이동
        }
        setPayParam(param);
        setIsPay(!isPay);
        // window.location.href = `/req/cmptReqsPayProgress?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}&cmptLId=${cmptLId}&usePoint=${confirmPoint}&payId=${res.data.object.payId}&cpmtLNm=${cmpt == null ? null : cmpt.cpmtLNm}`
      }
    }
  }

  useEffect(() => {
    if(!getCookieUserId()){
      window.location.href = `/cmpt/cmptDetail?cmptLId=${cmptLId}&gameKindNm=${gameKindNm}`;
    }
    if(cmpt == null) {
      getUser();
      getCmpt();
      getEnterReq();
    }
      
  }, []); 


  
  return (
    <Layout>

  <div className="contents contest">

    <div className="top_line">
      <h1 className="sub_title pc">{cmpt == null ? null : cmpt.gameKindNm}대회</h1>
      <h1 className="sub_title mo">대회참가 / {cmpt == null ? null : cmpt.gameKindNm}</h1>
      <a href="#" onClick={() => window.location.href = `/cmpt/cmptList`} className="btn_return pc">
        목록으로 돌아가기
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <section className="application payment">
      {/* <!-- menu tab & poster --> */}

      {/* <!-- mo ver title --> */}
      <h2 className="mo"><span>{cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
            </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
      {/* <!-- //mo ver title --> */}

      <div className="sub_menu">
        <div className="tab">
        <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >대회요강</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=game&gameKindNm="+gameKindNm} >세부종목</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=notice&gameKindNm="+gameKindNm} >알림/문의</a>
          <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=item&gameKindNm="+gameKindNm} >경품/기념품</a>
        </div>
        <div className="tit">참가신청하기</div>
        <div className="poster">
        <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
        </div>
      </div>
      {/* <!-- //menu tab & poster --> */}

      {/* <!-- content --> */}
      <div className="application_content">
        {/* <!-- pc ver title --> */}
        <div className="title pc"> 
          <h2><span>
          {cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
          </span>
          &nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
          <div className="tx_type04">
            <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
            <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
            <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </div>
        </div>
        {/* <!-- //pc ver title --> */}

        {/* <!-- content info --> */}
        <div className="info step3">
          {/* <!-- select --> */}
          <div className="search_box">
            <div className="tit">
              <b>Step3. 신청내용 확인 및 결제</b>
            </div>
          </div>
          {/* <!-- //select --> */}
          
          <div className="list">
            <ul>
              <li>
                <span className="tit">대회명</span>
                <span><b>{cmpt == null ? null : cmpt.cmptLNm}</b></span>
              </li>
              <li>
                <span className="tit">기간</span>
                <span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD')+ " ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD')}</span>
              </li>
              <li>
                <span className="tit">개최장소</span>
                {cmptGym == null ?  null : cmptGym.map((item, index) => (
                <span  style={{cursor:"POINTER",textDecoration:"underline"}}onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}`}>{item.gymNm }</span>
                ))}
              </li>
            </ul>
            <ul>
              <li>
                <span className="tit">이름</span>
                <span><b>{user == null ? null : user.userNm}</b></span>
              </li>
              <li>
                <span className="tit">생년월일</span>
                <span>{user == null ? null : user.userBirth.substring(0,4) + "년 " + user.userBirth.substring(4,6) + "월 " + user.userBirth.substring(6,8) +"일"}</span>
              </li>
              <li>
                <span className="tit">주소</span>
                <span>{user == null ? null : user.userAddr + " " + user.userDtlAddr}</span>
              </li>
              <li>
                <span className="tit">전화번호</span>
                <span>{user == null ? null : user.userPhone.substring(0,3) + "-" + user.userPhone.substring(3,7) + "-" + user.userPhone.substring(7,12)}</span>
              </li>
              <li>
                <span className="tit">이메일</span>
                <span>{user == null ? null : user.userEmail}</span>
              </li>
              <li className="details">
                <span className="tit">세부종목</span>
                <div className="detail_box" style={{padding:"20px; 10px;"}}>
                  <span className="tit mo" >세부종목</span>
                  {EnterReqList == null ? null : EnterReqList.map((item, index) => (
                  <div  key={index + "req"}>
                    <div>
                      <p className="first_line">
                      <span style={{"width" : "40%"}}>{item.gameLNm}</span>
                      {item.gameDivsLValue0 == null || item.gameClsfKindCd0 == 'GAME_CLSF_KIND_CD_05'? null : <span>{item.gameDivsLValue0}</span>}
                      {item.gameDivsLValue1 == null || item.gameClsfKindCd1 == 'GAME_CLSF_KIND_CD_05' ? null : <span>{item.gameDivsLValue1}</span>}
                      {/* {item.gameDivsLValue2 == null ? null : <span>{item.gameDivsLValue2}</span>}/ */}
                      </p>
                      {item.teamYn == 'N' && item.prtnYn == "N" ?
                      null 
                      :
                      <p className="tx_type05">{item.teamYn == 'N' ? "" :  " 팀 : " + (item.enterTeamNm  == null ? '팀없음' : item.enterTeamNm )+ " / "}&nbsp;&nbsp;&nbsp;{item.prtnYn != "Y" ?   ""  : "파트너 : " + item.prtnUserNm.slice(0, -1) + ' * '}</p> 
                      }
                    </div>
                    <div >
                      {checkGameDivs(item.gameClsfKindCd0,item.gameDivsLValue0,item.gameDivsLId0,item.enterReqId, item.gameDivsNm0)}
                      {checkGameDivs(item.gameClsfKindCd1,item.gameDivsLValue1,item.gameDivsLId1,item.enterReqId, item.gameDivsNm1)}
                      {checkGameDivs(item.gameClsfKindCd2,item.gameDivsLValue2,item.gameDivsLId2,item.enterReqId, item.gameDivsNm2)}
                      {checkGameDivs(item.gameClsfKindCd3,item.gameDivsLValue3,item.gameDivsLId3,item.enterReqId, item.gameDivsNm3)}
                      {checkGameDivs(item.gameClsfKindCd4,item.gameDivsLValue4,item.gameDivsLId4,item.enterReqId, item.gameDivsNm4)}
                      {checkGameDivs(item.gameClsfKindCd5,item.gameDivsLValue5,item.gameDivsLId5,item.enterReqId, item.gameDivsNm5)}
                      {checkGameDivs(item.gameClsfKindCd6,item.gameDivsLValue6,item.gameDivsLId6,item.enterReqId, item.gameDivsNm6)}
                      {checkGameDivs(item.gameClsfKindCd7,item.gameDivsLValue7,item.gameDivsLId7,item.enterReqId, item.gameDivsNm7)}
                      {checkGameDivs(item.gameClsfKindCd8,item.gameDivsLValue8,item.gameDivsLId8,item.enterReqId, item.gameDivsNm8)}
                      {checkGameDivs(item.gameClsfKindCd9,item.gameDivsLValue9,item.gameDivsLId9,item.enterReqId, item.gameDivsNm9)}
                    </div>
                    <div className="pay1" style={{textAlign:"left",marginTop:"5px"}} >금액:{item.enterFare}</div>
                  </div>
                ))}
                </div>
              </li>
              <li className="total">
                <span className="tit">결제금액</span>
                <span className="pay2">{fare.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                  {/* 창원시가 주최하는 대회라서 선거법 위반으로 인해 결제금액 20000원과 배송비 따로 보여야되서 이렇게 표현 */}
                  {(cmpt != null && cmpt.cmptLId == '240821_GAME_KIND_0016_000001_0001') ? '(기념품 배송비 : 3,000원 포함) ' : null}
                </span>
              </li>
              <li className="deduct">
                <span className="tit">포인트차감</span>
                <div>
                  <span>{user == null ? null : user.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} - </span>
                  <input type="text" name="point" value={usePoint} onChange={handleUsePoint}/>
                  <button className="btn_type3" onClick={clickPoint}>포인트 사용</button>
                </div>
              </li>
              <li className="total">
                <span className="tit tx_type06">최종결제금액</span>
                <span className="pay3">{(fare - confirmPoint).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원 
                  {/* 창원시가 주최하는 대회라서 선거법 위반으로 인해 결제금액 20000원과 배송비 따로 보여야되서 이렇게 표현 */}
                {(cmpt != null && cmpt.cmptLId == '240821_GAME_KIND_0016_000001_0001') ? '(기념품 배송비 : 3,000원 포함) ' : null}
                </span>
              </li>
            </ul>
          </div>

          <p className="tx_type07">위와 같은 내용으로 대회참가를 신청하시겠습니까 ?</p>
          <button className="btn_application okay" onClick={createGameDvisValue}>신청확인 <br/> 결제하기</button>

        </div>
        {/* <!-- //content info --> */}
        
      </div>
      {/* <!-- //content --> */}
    </section>
    {!isPay ? null : <KiwoomPay param={payParam}> </KiwoomPay>}
  </div>
  </Layout>
  );
}
export default CmptReqsPay;