import React, { useEffect ,useState, useRef} from "react";
import {getAccessToken, getCookie, getRefreshToken, getUserProfileFileUrl,logout,getIsAutoLogin,fn_axios,setingIsLogin} from "../common/common.tsx";
import "../assets/js/ui.js";
import {API} from '../config/config.tsx';
// import { Link } from "react-router-dom";
// import "../assets/js/keen-slider.min.js";


// document.addEventListener("DOMContentLoaded", function(){
//     //방법 : 1
//     // const vh = window.innerHeight * 0.01;
//     // document.documentElement.style.setProperty('--vh', `${vh}px`);
//     // console.log(vh);

//     // 방법 2
//     if(navigator.userAgent.indexOf('iPhone')!= -1) {   
//         console.log(navigator.userAgent.indexOf('iPhone')); 
//         window.addEventListener("load", 
//             function() {        
//                 window.scrollTo(0, 1);
//             }, false);
//     } 
//     else {     
//         window.scrollTo(0, 1);
//     }
//      });
function Header() {
    const [isMenuToggle, setIsMenuToggle] = useState(false);
    const [profile, setProfile] = useState("profile default");
    const [searchKeyword, setSearchKeyword] = useState("");
    const checkLogin = () => {
        if(getAccessToken() == null && getRefreshToken() != null && getIsAutoLogin() == 'True') {
          autoLogin();
        } else if(getAccessToken() != null && getUserProfileFileUrl() != "" && getUserProfileFileUrl() != null) {
            setProfile("profile");
        } else {
            setProfile("profile default");
        }
    }

    const autoLogin = () => {
      const param = {
        method : 'get',
        url : `${API.BACKEND}/v1/user/autoLogin`,
        params : {}
      }
      fn_axios(param,callbackAutoLogin,null);
    }

    const callbackAutoLogin = (res) => {
      if(res.data.status) {
        window.location.href = "/";
      } else {
        alert("자동 로그인에 실패햇습니다.");
        setingIsLogin(false);
        console.log(res.data.message);
      }
    }

    const clickProfile = () => {
        if(getAccessToken() != null) {
            window.location.href="/user/mypage";            
        } else {
            window.location.href="/user/login";
        }
    }

    useEffect(() => {
        checkLogin();
      },[]);
    
  const handleSearchKeyPress = (event) => {
    if (event.key === 'Enter') {
      mainSearch();
    }
  }

  const mainSearch = () => {
    if(searchKeyword) window.location.href="/search?searchKeyword=" + searchKeyword;
  }
  const toggleMenu = (e) => {
    setIsMenuToggle(!isMenuToggle);
    document.documentElement.classList.toggle('mobile_menu_open');
  }
  const handleSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };

 return (
<header>
    <div className="mo_gnb">
      <a onClick={toggleMenu} className={isMenuToggle ? "active mobile_menu_btn" :  "mobile_menu_btn"} >
        <span></span>
        <span></span>
        <span></span>
      </a>
      <div className="mobile_menu_list">
        <a onClick={() => window.location.href = `/cmpt/cmptList`}>대회참가</a>
        <a onClick={() => window.location.href = `/gym/gymList`}>그라운드</a>
        <a onClick={() => window.location.href = `/event/eventList`}>이벤트</a>
        <a onClick={() => window.location.href = `/board/boardList`}>고객센터</a>
        <div className="sub">
          <a  onClick={() => window.location.href = `/board/boardList`}>공지사항</a>
          <a >F A Q</a>
          <a >회사소개</a>
          <a >이용약관</a>
        </div>
        {(getAccessToken() == null || getAccessToken() == "") ? 
          null
        : 
        <a href="#" onClick={() => window.location.href = `/user/mypage`}>마이페이지</a>
        }

        {(getAccessToken() == null || getAccessToken() == "") ? 
          null
        : 
          <div className="sub">
            <a onClick={() => window.location.href = `/user/mypage`}>개인정보</a>
            <a onClick={() => window.location.href = `/user/userReqCmptList`}>참가대회</a>
            <a >관심대회</a>
            <a onClick={() => window.location.href = `/user/point`}>포인트</a>
          </div>
        }
        
        
        <div className="sub2">
        {(getAccessToken() == null || getAccessToken() == "") ? 
          <a onClick={() => window.location.href = `/user/joinAgree`}>회원가입</a>
        : 
          <a onClick={() => logout()}>로그아웃</a>
        }
          
          <a >대회개설</a>
          <a >대회관리자신청</a>
        </div>
        
        <div className="copyright">
          <p><b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</p>
          <span>Copyright(c) 2023. wooriground.com. All right reserved.</span>
        </div>
      </div>
    </div>
    {/* <!-- //mo --> */}
    <div className="logo">
    <a  onClick={() => window.location.href = "/"}>  <img src='/assets/images/common/logo.png' alt="woog logo"/></a>
    </div>
    
    <div className="top_right">
      {/* <!-- pc --> */}
      <div className="gnb pc">
        <a onClick={() => window.location.href = `/cmpt/cmptList`}>대회참가</a>
        <a onClick={() => window.location.href = `/gym/gymList`}>그라운드</a>
        <a onClick={() => window.location.href = `/event/eventList`}>이벤트</a>
        <a onClick={() => window.location.href = `/board/boardList`}>고객센터</a>
      </div>
      {/* <!-- //pc --> */}
      <div className="search">
        <input type="text" name="top_search" value={searchKeyword}  onChange={handleSearchKeyword} onKeyPress={handleSearchKeyPress}  id="top_search" />
        <label htmlFor="top_search" onClick={mainSearch}></label>
      </div>
      <div className="ico_search mo" onClick={ () => window.location.href ='/search'}></div>

      
      <div className={profile} onClick={clickProfile}/*onClick="location.href='/05_mypage/mypage_info.html'"*/>
        {(getAccessToken() != null && getUserProfileFileUrl() != "" && getUserProfileFileUrl() != null) ? <img src={getUserProfileFileUrl()} alt="profile" /> : null}
      </div>
    </div>
  </header>
  )
}
export default Header;