import KeenSlider from "keen-slider";


window.onload = function() {
 // footer top btn
 

  // Keen Slider Initialization
 
  // initializeKeenSlider(); 

  // 잘 작동안해서 header.tsx 로 이동 : toggleMenu
  // mobile menu
  // if(document.querySelector('.mobile_menu_btn')) {
  //   document.querySelector('.mo_gnb .mobile_menu_btn').addEventListener('click', function() {
  //     this.classList.toggle('active');
  //     document.documentElement.classList.toggle('mobile_menu_open');
  //   });
  // }

  // main accordion
  document.addEventListener("click", function(event) {
    if (event.target.classList.contains("schedule") || event.target.classList.contains("btn_plus")) {
      document.querySelector('.schedule .list .event').classList.toggle('open');
    }
  });

  // contest accordion
  document.addEventListener("click", function(event) {
    if (event.target.classList.contains("btn_accordion")) {
      event.target.previousElementSibling.classList.toggle('open');
      event.target.classList.toggle('open');
    }
  });

  // popup open
  document.addEventListener("click", function(event) {
    if (event.target.classList.contains("popup_open")) {
      document.querySelector('.contents .dim_layer').style.display = 'block';
      event.target.closest('body').classList.add('dim_bg');
      document.body.style.overflowY = 'scroll';
    }
    if (event.target.id === "btnClosePartner" || event.target.id === "btnCloseTeam") {
    //   document.querySelector('.contents .dim_layer').style.display = 'none';
      // event.target.closest('body').classList.remove('dim_bg');
      document.querySelector('body').classList.remove('dim_bg');
      console.log('test');
    //   document.querySelector('.dim_layer').removeAttribute('style');
    //   document.body.removeAttribute('style');
    }
  });

  if(document.querySelector('footer .btn_top')) {
    document.querySelector('footer .btn_top').addEventListener('click', function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  }



  // tab
  const tabBtns = document.querySelectorAll(".tab .tab_title a");
  const tabContents = document.querySelectorAll(".tab .contest");

  if(tabBtns) { 
    tabBtns.forEach((btn, i) => {
      btn.addEventListener("click", function() {
        tabBtns.forEach(btn => btn.classList.remove("active"));
        this.classList.add("active");
        tabContents.forEach(content => content.classList.remove("active"));
        tabContents[i].classList.add("active");
      });
    });
  }
};
  // keen slider dot
  const navigation_dot = (slider1)  => {
    let wrapper, dots;

    function markup(remove) {
      wrapperMarkup(remove);
      dotMarkup(remove);
    }

    function removeElement(element) {
      element.parentNode.removeChild(element);
    }

    function createDiv(className) {
      var div = document.createElement("div");
      var classNames = className.split(" ");
      classNames.forEach(name => div.classList.add(name));
      return div;
    }

    function wrapperMarkup(remove) {
      if (remove) {
        var parent = wrapper.parentNode;
        while (wrapper.firstChild)
          parent.insertBefore(wrapper.firstChild, wrapper);
        removeElement(wrapper);
        return;
      }
      wrapper = createDiv("navigation-wrapper");
      slider1.container.parentNode.appendChild(wrapper);
      wrapper.appendChild(slider1.container);
    }

    function dotMarkup(remove) {
      if (remove) {
        removeElement(dots);
        return;
      }
      dots = createDiv("dots");
      slider1.track.details.slides.forEach((_e, idx) => {
        var dot = createDiv("dot");
        dot.addEventListener("click", () => slider1.moveToIdx(idx));
        dots.appendChild(dot);
      });
      wrapper.appendChild(dots);
    }

    function updateClasses() {
      var slide = slider1.track.details.rel;
      Array.from(dots.children).forEach(function (dot, idx) {
        idx === slide
          ? dot.classList.add("dot_active")
          : dot.classList.remove("dot_active");
      });
    }

    slider1.on("created", () => {
      markup();
      updateClasses();
    });
    slider1.on("optionsChanged", () => {
      markup(true);
      markup();
      updateClasses();
    });
    slider1.on("slideChanged", () => {
      updateClasses();
    });
    slider1.on("destroyed", () => {
      markup(true);
    });
  }
  

// Function to initialize Keen Slider
export function initializeKeenSlider() {
// keen slider all

  const navigation = (slider)  => {
    let wrapper, dots, arrowLeft, arrowRight;

    function markup(remove) {
      wrapperMarkup(remove);
      dotMarkup(remove);
      arrowMarkup(remove);
    }

    function removeElement(element) {
      element.parentNode.removeChild(element);
    }

    function createDiv(className) {
      var div = document.createElement("div");
      var classNames = className.split(" ");
      classNames.forEach(name => div.classList.add(name));
      return div;
    }

    function arrowMarkup(remove) {
      if (remove) {
        removeElement(arrowLeft);
        removeElement(arrowRight);
        return;
      }
      arrowLeft = createDiv("arrow btn_prev");
      arrowLeft.addEventListener("click", () => slider.prev());
      arrowRight = createDiv("arrow btn_next");
      arrowRight.addEventListener("click", () => slider.next());

      wrapper.appendChild(arrowLeft);
      wrapper.appendChild(arrowRight);
    }

    function wrapperMarkup(remove) {
      if (remove) {
        var parent = wrapper.parentNode;
        while (wrapper.firstChild)
          parent.insertBefore(wrapper.firstChild, wrapper);
        removeElement(wrapper);
        return;
      }
      wrapper = createDiv("navigation-wrapper");
      slider.container.parentNode.appendChild(wrapper);
      wrapper.appendChild(slider.container);
    }

    function dotMarkup(remove) {
      if (remove) {
        removeElement(dots);
        return;
      }
      dots = createDiv("dots");
      slider.track.details.slides.forEach((_e, idx) => {
        var dot = createDiv("dot");
        dot.addEventListener("click", () => slider.moveToIdx(idx));
        dots.appendChild(dot);
      });
      wrapper.appendChild(dots);
    } 

    function updateClasses() {
      var slide = slider.track.details.rel;
      slide === 0
        ? arrowLeft.classList.add("arrow_disabled")
        : arrowLeft.classList.remove("arrow_disabled");
      slide === slider.track.details.slides.length - 1
        ? arrowRight.classList.add("arrow_disabled")
        : arrowRight.classList.remove("arrow_disabled");
      Array.from(dots.children).forEach(function (dot, idx) {
        idx === slide
          ? dot.classList.add("dot_active")
          : dot.classList.remove("dot_active");
      });
    }

    slider.on("created", () => {
      markup();
      updateClasses();
    });
    slider.on("optionsChanged", () => {
      markup(true);
      markup();
      updateClasses();
    });
    slider.on("slideChanged", () => {
      updateClasses();
    });
    slider.on("destroyed", () => {
      markup(true);
    });
  }

  // pc visual
  if(document.getElementById('my-keen-slider1')) {
    var slider = new KeenSlider("#my-keen-slider1", {
      slides: {
        spacing: 20,
      }
    }, [navigation_dot]); 
  }

  // mo visual
  if(document.getElementById('my-keen-slider2')) {
    var slider = new KeenSlider("#my-keen-slider2", {
      loop: true
    }, [navigation]);
  }

  // pc main slide1
  if(document.getElementById('my-keen-slider3')) {
    var slider = new KeenSlider("#my-keen-slider3", {}, [navigation_dot]);
  }

  // mo main slide1 
  if(document.getElementById('my-keen-slider4')) {
    var slider = new KeenSlider("#my-keen-slider4", {
      slides: {
        perView: "auto",
        spacing: 20,
      },
    });
  }

  // pc main slide2
  if(document.getElementById('my-keen-slider5')) {
    var slider = new KeenSlider("#my-keen-slider5", {}, [navigation_dot]);
  }

  // mo main slide2 
  if(document.getElementById('my-keen-slider6')) {
    var slider = new KeenSlider("#my-keen-slider6", {
      slides: {
        perView: "auto",
        spacing: 20,
      },
    });  
  }
}


export function initializeKeenSlider7() {
  var slider1 = new KeenSlider("#my-keen-slider7", {}, [navigation_dot]);
}