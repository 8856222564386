import axios  from 'axios';
import {API} from '../config/config.tsx';
    
  axios.defaults.withCredentials = true;
  axios.defaults.headers['Access-Control-Allow-Origin'] = `${API.BACKEND}/*`;
  axios.defaults.headers['Access-Control-Allow-Credentials'] = true;
  
  //JWT 설정이 필요한 경우 아래 두개 토큰 사용.
  // const [cookies] = useCookies(['Access_Token','Refresh_Token']);
  // const accessToken = cookies.Access_Token;
  // const refreshToken = cookies.Refresh_Token;
  const axiosInstance = axios.create({
      // baseURL : axiosParam.url ,
      // data : axiosParam.paramJson,
      // timeout:5000, //timeout 5초
      headers :{
          "Content-Type": 'application/json'
      },
  })
  axiosInstance.interceptors.request.use(
      config => {
          //config에는 위의 axiosInstance 객체를 이용하여 request를 보냈을떄의 모든 설정값
          //토큰 처리 시 여기에 처리. 우선은 axios.defualt.withCredentials 로 처리.
          return config;
      },
      err => {
          console.log(err);
          return Promise.reject(err);
      }
  );

  axiosInstance.interceptors.response.use(
      config => {
        return config;
      },
      ({ config, request, response, ...err }) => {
        const errMsg = 'Error Message';
        return Promise.reject({
          config,
          message: errMsg,
          response,
          ...err,
        });
      },
    );
export default axiosInstance;      