// kakao.tsx
import React from 'react';
import { useEffect } from "react";
import { KAKAO_REST_APP_KEY, API} from '../../config/config.tsx';
import {fn_axios} from '../../common/common.tsx';
import Layout from '../../layout/Layout.tsx';

const KakaoToken = () => {
  const params= new URL(document.location.toString()).searchParams;
  const code = params.get('code');
  const grantType = "authorization_code";

  const getKakaoToken = () => {
    fetch(
        'https://kauth.kakao.com/oauth/token',
        { method : "POST" ,
          headers :  { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" },
          body: `grant_type=${grantType}&client_id=${KAKAO_REST_APP_KEY}&redirect_uri=${API.KAKAO_REDIRECT_URL}&code=${code}`
        }
    )
    .then(res => res.json()) 
    .then(data => {
      if(data.access_token) {
        console.log(data.access_token);
        const access_token = data.access_token;
        const param = {
          method : 'POST',
          url : `${API.BACKEND}/v1/user/loginKakao`,
          params : access_token
        }
        fn_axios(param,kakaoLoginCallback,null);

      } else {
        console.log('FAIL' + data);
      }
    })
    .catch((Error: any) => {
        console.log(Error)
    })
  }
  const kakaoLoginCallback = (res) => {
    if(res.data.status) {
      alert(res.data.message);
      window.location.href = "/";
    } else {
      alert(res.data.message);
      window.location.href = "/user/login";
    } 
  } 
  useEffect(() => {
    if(!code) return;
    getKakaoToken();
  }, [])

return (
  <Layout>
  <div className="contents join">
    <h1 style={{textAlign: 'center'}}>잠시만 기다려 주세요!<br/>로그인 중입니다.</h1>
  </div>
  </Layout>
)
}
  export default KakaoToken;