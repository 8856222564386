import React from 'react';
import {useState,useEffect } from 'react';
import {API, KAKAO_AUTH_URL,NAVER_AUTH_URL} from '../../config/config.tsx';
import {fn_axios} from '../../common/common.tsx';
import Layout from '../../layout/Layout.tsx';
import {getAccessToken,getCookieUserEmail,setingEmail,setingIsLogin} from "../../common/common.tsx";

const Login: React.FC = () => {
	const [userEmail, setUserEmail] = useState(getCookieUserEmail());
 	const [userPwd, setUserPwd] = useState("");
 	const [isEmail, setIsEmail] = useState(false);
 	const [isLogin, setIsLogin] = useState(false);
    const handleUserEmail = (e) => {
			setUserEmail(e.target.value);
  }; 
    const handleIsEamil = (e) => {
		setIsEmail(!isEmail);
  }; 
	const handleIsLogin = (e) => {
		setIsLogin(!isLogin);
  }; 
  const openKakaoLogin = () => {
	console.log(`${KAKAO_AUTH_URL}`);
	window.open(`${KAKAO_AUTH_URL}` , "_self");
  };
 
  const openNaverLogin = () => {
	console.log(`${NAVER_AUTH_URL}`);
	window.open(`${NAVER_AUTH_URL}` , "_self");
  };
 
  const handleUserPwd = (e) => {
    setUserPwd(e.target.value);
  };
  const loginCallback = function(res) {
	if(res.data.status) {
		window.location.href = "/";
	} else {
		alert("로그인에 실패했습니다.\n 아이디/패스워드를 확인해주세요.");
		console.log(res.data.message);
	}
  }

	const onClickLogin = () => {
		setingEmail(isEmail,userEmail);
		setingIsLogin(isLogin);
		const param = {
			method : 'POST',
			url : `${API.BACKEND}/v1/user/login`,
			params : {userEmail: userEmail,
						userPwd: userPwd}
		}
		fn_axios(param,loginCallback,null);
  };
  useEffect(() => {
    if(getAccessToken() != null ) {
		window.location.href = "/";
	}
	setIsEmail(getCookieUserEmail() == null ? false : true);
    
  }, [])
	return (
		<Layout>
			{/* <!-- contents --> */}
			<div className="contents login">
				<h1>로그인</h1>
				<section className="default_login">
					<div className="form">
						<input type="text" value={userEmail} onChange={handleUserEmail} placeholder="아이디 : 이메일"/>
						<input type="password" value={userPwd} onChange={handleUserPwd} placeholder="비밀번호"/>
					</div>
					<button className="btn_type1 arrow" onClick={onClickLogin}>로그인하기</button>
				</section>
				<div className="application_content mine" style={{marginBottom:"10px"}}>
					<span style={{margin:"5px"}}>아이디 저장 &nbsp;<input type="checkbox" style={{width:"10px;"}} onClick={handleIsEamil}  checked={isEmail}/></span>
					<span style={{margin:"5px"}}>자동로그인 &nbsp;<input type="checkbox" style={{width:"10px;"}} onClick={handleIsLogin}  checked={isLogin}/></span>
				</div>
				<div className="mine" style={{marginBottom:"10px"}}>
					<a href="/user/findId">아이디(이메일)</a> &#47;
					<a href="/user/findPwd">비밀번호</a>
					<span>찾기</span>
				</div>
				<section className="sns_login">
				<h2>SNS 계정으로 간편로그인 / 회원가입</h2>
				{/* <a href="javascript:;"><img src="/assets/images/common/snslogo1.png" alt="facebook"/></a> */}
				{/* <a href="#" onClick={openNaverLogin }><img src="../assets/images/common/snslogo2.png" alt="naver"/></a> */}
				<a href="#" onClick={openKakaoLogin}><img src="../assets/images/common/snslogo3.png" alt="kakaotalk"/></a>
				{/* <button className="btn_type2 arrow" onClick={() => {window.location.href='./joinAgree'}}>우리그라운드 회원가입</button> */}
				<button className="btn_type2 arrow" onClick={() => {window.location.href='./ageConfirm'}}>우리그라운드 회원가입</button>
				</section>
				<div className="tx_type02 pc"><b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</div>
			</div>
		</Layout>
	);
}
		
export default Login;