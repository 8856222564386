import React from "react";
import {useState, useEffect} from 'react';
import {fn_axios} from "../common/common.tsx";
import {API} from '../config/config.tsx';
import  {initializeKeenSlider} from "../assets/js/ui.js";

function Slider() {
    const [cmptBnnrList, setCmptBnnrList] = useState([]);


    const getBnnrPaging = () => {
        const param = {
        method : 'GET',
        url : `${API.BACKEND}/${API.VERSION}/bnnr/getCmptBnnrPaging`,
        params : {pageNum : 1
                  }
        }
        fn_axios(param,callbackGetBnnrPaging,null);
      }
    
      const callbackGetBnnrPaging = (res) => {
        setCmptBnnrList(res.data.object);
      }
    const drawPcSubBnnrList = (list) => {
        let itemElements = [];
      
        for (let i = 0; i < list.length; i += 4) {
          itemElements.push(
            <div className={`silde01 keen-slider__slide number-slide${i === 0 ? "1" : (i / 4) + 1}`}>
              <div>
                <img src={list[i].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i].bnnrLink}&bnnrLId=${list[i].bnnrLId}`} />
                <p>{list[i].bnnr_title}</p>
              </div>
              { (i + 1) < list.length &&
              <div>
                <img src={list[i + 1].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 1].bnnrLink}&bnnrLId=${list[i + 1].bnnrLId}`} />
                <p>{list[i + 1].bnnr_title}</p>
              </div>
              }
              { (i + 2) < list.length &&
              <div>
                <img src={list[i + 2].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 2].bnnrLink}&bnnrLId=${list[i + 2].bnnrLId}`} />
                <p>{list[i + 2].bnnr_title}</p>
              </div>
              }
              { (i + 3) < list.length &&
              <div>
                <img src={list[i + 3].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 3].bnnrLink}&bnnrLId=${list[i + 3].bnnrLId}`} />
                <p>{list[i + 3].bnnr_title}</p>
              </div>
              }
            </div>
          );
        }
      
        return itemElements;
      }
      const drawMobileSubBnnrList = (list) => {
        let itemElements = [];
        let i = 0;
          itemElements.push(
            <div className="silde01">
              <div className="keen-slider__slide number-slide1">
                  <img src={list[i].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i].bnnrLink}&bnnrLId=${list[i].bnnrLId}`} />
                  <p>{list[i].bnnr_title}</p>
              </div>
              { (i + 1) < list.length &&
              <div className="keen-slider__slide number-slide2">
                <img src={list[i + 1].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 1].bnnrLink}&bnnrLId=${list[i + 1].bnnrLId}`} />
                <p>{list[i + 1].bnnr_title}</p>
              </div>
              }
              { (i + 2) < list.length &&
              <div className="keen-slider__slide number-slide3">
                <img src={list[i + 2].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 2].bnnrLink}&bnnrLId=${list[i + 2].bnnrLId}`} />
                <p>{list[i + 2].bnnr_title}</p>
              </div>
              }
              { (i + 3) < list.length &&
              <div className="keen-slider__slide number-slide4">
                <img src={list[i + 3].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 3].bnnrLink}&bnnrLId=${list[i + 3].bnnrLId}`} />
                <p>{list[i + 3].bnnr_title}</p>
              </div>
              }
            </div>
          );
      
        return itemElements;
      }

    useEffect(() => {
    if(cmptBnnrList == null || cmptBnnrList.length == 0) {
        getBnnrPaging();
    }
    }, [])

    useEffect(() => {
    if(cmptBnnrList != null && cmptBnnrList.length > 0) {
        initializeKeenSlider();
    }
    }, [cmptBnnrList])

  return (  
    <div>
        {/* <HelmetProvider>
            <Helmet>
                <script type="text/javascript" src='../../assets/js/keen-slider.min.js'></script>
                <script type="text/javascript" src='../../assets/js/ui.js'></script>	
            </Helmet>
        </HelmetProvider> */}
        <section className="slide_list">
        <div className="title_box">
            <div className="tit">당신이 찾고있는 <br className="mo"/> 바로 그 대회</div>
            <a className="btn_r_black" href="#">추천대회</a>
        </div>
        {/* <!-- pc ver slide1 --> */}
        <div className="pc">
              <div id="my-keen-slider3" className="keen-slider slide_box"> 
              {(cmptBnnrList == null || cmptBnnrList.length == 0 ) ? null : drawPcSubBnnrList(cmptBnnrList)}
              </div>
            </div>
            {/* <!-- //pc ver slide1 --> */}
            {/* <!-- mo ver slide1 --> */}
            <div className="mo">
              <div id="my-keen-slider4" className="keen-slider slide_box"> 
                {(cmptBnnrList == null || cmptBnnrList.length == 0 ) ? null : drawMobileSubBnnrList(cmptBnnrList)}
              </div>
            </div>
            {/* <!-- //mo ver slide1 --> */}
        </section>
    </div>  
  );
}

export default Slider;