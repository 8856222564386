import React from 'react';
import { useRef } from "react";
import {useState,useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {fn_axios,fn_post_fileUpload_axios} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import Layout from '../../layout/Layout.tsx';
import {getAccessToken, isUnderNineteen} from "../../common/common.tsx";
import GameItemProvider from "../../common/GameItemButton.tsx";
import LareaAddr from "../../common/LareaAddr.tsx";

import DaumPostcode from "react-daum-postcode";
import Modal from "react-modal"; // 추가

function Join() {
	const location = useLocation();
	const { p_userName, p_userPhone,p_userGender,p_userBirthday,p_isAge , p_adertPhone,p_adertBrithday } = location.state || {};
	const { search } = useLocation();
	const queryParams = new URLSearchParams(search);
	const isAgree = queryParams.get('isAgree') == "true";
	const isAge = p_isAge == "Y";
	const isAdvert = queryParams.get('isAdvert') == "true";

	// FORM
	const [userEmail, setUserEmail] = useState("");
	const [userNickname, setUserNickname] = useState("");
	const [userName, setUserName] = useState(p_userName || "");
	const [userPaswword1, setUserPassword1] = useState("");
	const [userPaswword2, setUserPassword2] = useState("");
	const [userProfileFileId, setUserProfileFileId] = useState("");
	const [userPhone, setUserPhone] = useState(p_userPhone || "");
	const [userAddr, setUserAddr] = useState("");
	const [userDtlAddr, setUserDtlAddr] = useState("");
	const [lareaAddr, setLareaAddr] = useState([]);
	const [userBirth, setUserBirth] = useState(p_userBirthday || "");
	const [gameItems, setGameItems] = useState([]);
	const [userGndr, setUserGndr] = useState(p_userGender || "");

	//값 체크용
	const [profile, setProfile] = useState("");
	const [phoneCheckNum, setPhoneCheckNum] = useState("");
	const [checkNum, setCheckNum] = useState("");
	const [checkTimer, setCheckTimer] = useState<number>(0);
	const [phoneReadonly, setPhoneReadonly] = useState<boolean>(false);
	const [isTimer, setIsTimer] = useState<boolean>(true);
	const [profileImage, setProfileImage] = useState("/assets/images/common/profile_photo1.jpg");
	const [isOpen, setIsOpen] = useState<boolean>(false); //추가



	const adertPhone = p_adertPhone;
	const adertBrithday = p_adertBrithday;
	const divPhoneCheckRef = useRef(null);
	const timerRef = useRef(null);
	const phoneCheck =useRef<HTMLInputElement>(null);


	//값 유효 체크가 필요한 경우.
	const [checkMap,setCheckMap] = useState({ "userEmail":false
											, "userNickname": false
											, "password": false
											, "userPhone": false
											, "userProfileFileId": false
											, "userAddr": false
											, "userDtlAddr": false});


	

	const handleUserEmail = (e) => {
		setUserEmail(e.target.value);
	}; 
	const handleUserNickname = (e) => {
		setUserNickname(e.target.value);
	}; 
	const handleUserName = (e) => {
		setUserName(e.target.value);
	}; 
	const handleUserPaswword1 = (e) => {
		setUserPassword1(e.target.value);
	}; 
	const handleUserPaswword2 = (e) => {
		setUserPassword2(e.target.value);
	}; 
	const handleUserPhone = (e) => {
		setCheckMap({...checkMap,["userPhone"]: false});
		setUserPhone(e.target.value);
	}; 
	const handlePhoneCheckNum = (e) => {
		setPhoneCheckNum(e.target.value);
	}; 
	const handleUserDtlAddr = (e) => {
		setUserDtlAddr(e.target.value);
		if(e.target.value !== null) {
			setCheckMap({...checkMap,["userDtlAddr"]: true});
		}
	}; 
	const handleUserAddr = (e) => {
		setCheckMap({...checkMap,["userAddr"]: false});
		setUserAddr(e.target.value);
	}; 
	const handleUserGndr = (e) => {
		setUserGndr(e.target.value);
	}; 
	const handleProfile = (e) => {
		setProfile(e.target.files);
		sendFile(e.target.files);
	}; 
	const handleUserBirth = (e) => {
		setUserBirth(e.target.value);
	}

	const checkEmail = () => {
		const emailRegEx = /^[A-Za-z0-9]([-_.]?[A-Za-z0-9])*@[A-Za-z0-9]([-_.]?[A-Za-z0-9])*\.[A-Za-z]{2,3}$/;
		setCheckMap({...checkMap,["userEmail"]: false});
		if(userEmail == "") {
			alert("이메일을 입력해주세요.");
			return false;
		} else if(userEmail.match(emailRegEx) === null) {
			alert("이메일형식이 아닙니다.");
			return false;
		}
		return true;
	}

	const checkNickname = () => {
		const nicknameRegEx = /^[가-힣a-zA-Z0-9]+$/;
		const nicknameRegEx2 = /^[0-9]+$/;
		setCheckMap({...checkMap,["userNickname"]: false});

		if(userNickname.length < 2 ) {
			alert("두글자 이상 입력바랍니다.");
			return false;
		} else if(userNickname.substring(0,1).match(nicknameRegEx2) !== null) {
			alert("닉네임은 영어 혹은 한글로 시작해야 합니다.");
			return false;
		} else if(userNickname.match(nicknameRegEx) === null) {
			alert("닉네임은 영어, 숫자, 한글만 사용 가능합니다.");
			return false;
		}

		return true;
		
	}

	const checkPassword = () => {
		const passwordRegEx = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
		setCheckMap({...checkMap,["password"]: false});

		if(userPaswword1 !== userPaswword2) {
			alert("입력한 비밀번호가 일치하지 않습니다.");
			return false;
		} else if(userPaswword1.match(passwordRegEx) === null) {
			alert("숫자+영문자+특수문자 조합으로 8자리 이상 입력해주세요");
			return false;
		} else {
			setCheckMap({...checkMap,["password"]: true});
			return true;
		}
		
		
	}

	const onCheckEmail = () => {

		if(!checkEmail()) {
			return ;
		}

		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/checkEmail`,
			params : {userEmail: userEmail}
		}
		fn_axios(param,callBackOnChechEmail,null);
	}

	const callBackOnChechEmail = (res) => {
		if(res.data.status) {
			alert(res.data.message);
			setCheckMap({...checkMap,["userEmail"]: true});
		} else {
			alert(res.data.message);
			setCheckMap({...checkMap,["userEmail"]: false});
		}
	}
	const onCheckNickname = () => {
		if(!checkNickname()) {
			return;
		}
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/checkNickname`,
			params : {userNickname: userNickname}
		}
		fn_axios(param,callBackOnCheckNickname,null);
	};

	const callBackOnCheckNickname = (res) => {
		if(!res.data.status) {
			alert(res.data.message);
			setCheckMap({...checkMap,["userNickname"]: false});
		} else {
			alert(res.data.message);
			setCheckMap({...checkMap,["userNickname"]: true});
		}
	}

	const sendFile = (file) => {
		// if((!"image/png".match(file[0].type)) &&
		// 	(!"image/jpeg".match(file[0].type)) &&
		// 	(!"image/jpg".match(file[0].type))
		//     ) {
		// 	alert(".png, .jpeg, .jpg 파일만 업로드 가능합니다.");
		// 	return false;
		// }
		fn_post_fileUpload_axios(file,fileTestCallBack,null);
	}

	const fileTestCallBack = (res) => {
		if(res.data.status) {
			alert("파일이 정상적으로 업로드 되었습니다.");
			setProfileImage(res.data.object[0].atchFileUrl);
			setUserProfileFileId(res.data.object[0].atchFileId);
			setCheckMap({...checkMap,["userProfileFileId"]: true });
		} else {
			alert("파일 업로드에 실했습니다.");
			setCheckMap({...checkMap,["userProfileFileId"]: false});
		}
		
		
	}

	const sendPhoneVerification = () => {
		const phoneRegEx = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;

		if(checkMap["userPhone"]) {
			alert("인증이 완료되었습니다.");
			return ;
		}
		
		if(userPhone.match(phoneRegEx) === null) {
			alert("올바른 전화번호 형식이 아닙니다. \n '-'을 제외한 전화번호를 입력하세요.");
			return false;
		}
		const param = {
			method : 'POST',
			url : `${API.BACKEND}/v1/sms/sendPhoneVerification`,
			params : {userPhone: userPhone}
		}
		fn_axios(param,callbackSendPhoneVerification,null);

	}

	const callbackSendPhoneVerification = (res) => {
		setCheckNum(res.data.object);
		if(res.data.status) {
			alert(res.data.message);
			divPhoneCheckRef.current.style.setProperty('display', 'block');
			setIsTimer(false);
			CheckingTimer();
		} else {
			alert(res.data.message);
		}
		
		
	}

	const CheckingTimer = () => {
		setCheckTimer(300);
	}
	useEffect(() => {
		timerRef.current = setInterval(() => {
			setCheckTimer(checkTimer => checkTimer - 1);
		}, 1000);

		// 타이머 멈추기
		if(checkTimer <= 0){
			clearInterval(timerRef.current);
		};

		return () => clearInterval(timerRef.current);
		
	});

	const chechPhone = () => {

		if(checkMap["userPhone"]) {
			alert("인증이 완료되었습니다.");
			return ;
		}
		if(checkTimer <= 0) {
			alert("인증 시간을 초과하였습니다.");
			return;
		}

		
		if(checkNum === phoneCheckNum) {
			
			alert("본인 인증이 완료되었습니다.");
			setPhoneReadonly(true);
			setCheckMap({...checkMap,["userPhone"]: true});
			setIsTimer(true);
			setCheckTimer(0);

		} else {
			alert("인증번호가 일치 하지 않습니다.");
		}
	}

	const clickDaumAddr = () => {
		setIsOpen(!isOpen); //추가
	}

	const completeDaumAddr = (data:any) =>{
        // setZipcode(data.zonecode);
        setUserAddr(data.roadAddress);
        setIsOpen(false); //추가
		if(data.roadAddress !== null) {
			setCheckMap({...checkMap,["userAddr"]: true});
		}
    }

	const onSelectGameItemChange = (selectedValues) => {
		setGameItems(selectedValues);
	}

	const onSelectedLareaAddr =(selectedValues) => {
		setLareaAddr(selectedValues);

	}

	const clickJoin = () => {
		
		if(!checkPassword()) {
			return;
		}
		if(!userEmail) {
			alert("이메일을 입력해주세요.");
			return ;
		}
		if(!checkMap.userEmail) {
			alert("이메일이 올바르게 입력되지 않았습니다.");
			return ;
		}
		if(!userNickname) {
			alert("닉네임을 입력해주세요.");
			return ;
		}
		if(!checkMap.userNickname) {
			alert("닉네임이 올바르게 입력되지 않았습니다.");
			return ;
		}

		// 14세 이상인 경우와 미만인 경우 구분
		if(isAge) {

			if(userName == null || userName == ""){
				alert("본인인증을 통한 접근이 아닙니다.\n 본인인증 완료 후 진행바랍니다.");
				return ;
			}
			
			if(userPhone == null || userPhone == ""){
				alert("본인인증을 통한 접근이 아닙니다.\n 본인인증 완료 후 진행바랍니다.");
				return ;
			} 
			
			if(userBirth == null || userBirth == ""){
				alert("본인인증을 통한 접근이 아닙니다.\n 본인인증 완료 후 진행바랍니다.");
				return ;
			}
			if(userGndr == null || userGndr == ""){
				alert("본인인증을 통한 접근이 아닙니다.\n 본인인증 완료 후 진행바랍니다.");
				return ;
			}
			

		} else {
			// if(document.querySelector('#isPass') == null || document.querySelector('#isPass').value != "true"){
			// 	alert("보호자 인증 완료 해주세요.");
			// 	return ;
			// } 
			if(adertBrithday == null || isUnderNineteen(adertBrithday)){
				alert("보호자는 19세 이상이여야 합니다.");
				return ;
			} 
			if(!userBirth) {
				alert("생년월일을 선택해주세요.");
				return ;
			}
			if(!userName) {
				alert("이름을 입력해주세요.");
				return ;
			}
			if(!userPhone) {
				alert("휴대폰 번호를 입력해주세요.");
				return ;
			}
			if(!checkMap.userPhone) {
				alert("본인 인증이 완료되지 않았습니다.");
				if (phoneCheck.current !== null) {
					phoneCheck.current?.focus();
				} 
				return ;
			}

			if(userGndr === "") {
				alert("성별을 선택해주세요.");
				return ;
			}
		}
		
		if(!userAddr) {
			alert("주소를 입력해주세요.");
			return ;
		}
		if(!checkMap.userAddr) {
			alert("잘못된 주소입니다.");
			return ;
		}
		if(!userDtlAddr) {
			alert("상세 주소를 입력해주세요.");
			return ;
		}
		
		if(lareaAddr == null || lareaAddr.length == 0) {
			alert("관심지역을 선택해주세요.");
			return; 
		}
		if(gameItems === null || gameItems.length === 0) {
			alert("하나 이상의 관심 종목을 선택해주세요.");
			return ;
		}
		const param = {
			method : 'POST',
			url : `${API.BACKEND}/v1/user/join`,
			params : {userEmail: userEmail
					, userNickname : userNickname
					, userPwd : userPaswword1
					, userProfileFileId : userProfileFileId
					, userPhone : userPhone
					, userGndr : userGndr
					, userAddr : userAddr
					, userDtlAddr : userDtlAddr
					, lareaAddrList : lareaAddr
					, gameItems : gameItems
					, userNm : userName
					, userBirth : userBirth
					, prttPhone : adertPhone
					, tacdtAgreeYn : isAgree ? "Y":"N"
					// , isAge : isAge
					, advtRcptAgreeYn : isAdvert ? "Y":"N"
				}
		}
		fn_axios(param,callbackJoin,null);
	}

	const callbackJoin = (res) => {
		console.log(res);
		alert(res.data.message);
		if(res.data.status) {
			window.location.href = "/user/login";
		}
	}


	useEffect(() => {
		if(!p_isAge) {
			alert("본인인증 완료 후 진행바랍니다.");
			window.location.href = "/user/ageConfirm";
			return;
		}
		//로그인중이면 홈화면으로 이동.
		if(getAccessToken() != null ) {
			window.location.href = "/";
		}

		// 이용약관 동의 안한 경우 이용약관 동의
		if(!isAgree ) {
			alert("이용 약관에 동의하지 않았습니다. 이용약관 동의 바랍니다.");
			window.location.href = "/user/joinAgree";
			return;
		}
	  }, [])

	return (
		<Layout>
			
		{/* // <!-- contents --> */}
		<div className="contents join">
		  <h1>회원가입</h1>
		  {/* <!-- login --> */}
		  <section className="login_info">
			<div className="title">로그인 정보</div>
			<div className="form">
			  <div className="id">
				<input type="text" value={userEmail} onChange={handleUserEmail} placeholder="이메일"/>
				<label htmlFor="id" onClick={onCheckEmail}>중복확인</label>
				<p>ID는 이메일을 사용합니다. 이메일 정확하게 입력바랍니다.</p>
			  </div>
			  <div className="nickname">
				<input type="text" value={userNickname} onChange={handleUserNickname} placeholder="닉네임"/>
				<label htmlFor="nickname" onClick={onCheckNickname}>중복확인</label>
				<p>닉네임는 영문, 한글, 숫자 혼합 20자리 가능하며 반드시 영문 혹은 한글로 시작되어야 합니다.</p>
			  </div>
			  <div className="password">
				<input type="password" value={userPaswword1} onChange={handleUserPaswword1} placeholder="비밀번호"/>
				<input type="password" value={userPaswword2} onChange={handleUserPaswword2} onBlur={checkPassword} placeholder="비밀번호 확인"/>
				<p>비밀번호는 최소 8글자 이상어야 하며 영문, 숫자 및 특수문자가 반드시 포함되어야 합니다.</p>
			  </div>
			</div>
		  </section>
		  {/* <!-- profile --> */}
		  <section className="profile_info">
			<div className="title">프로필 정보</div>
			<div className="form">
			  <dl className="photo">
				<dt><img src={profileImage} alt="profile"/></dt>
				<dd>
				  <b>프로필 사진</b> <br/> 
				  가로크기 : 1,000 픽셀 <br/>
				  세로크기 : 1,000 픽셀 <br/>
				  파일형식 : JPG / PNG
				  <label htmlFor="file">
					<div className="btn_type1" >이미지 파일 등록</div>
				  </label>
				  <React.Fragment>
				  	<input type="file" id="file" onChange={handleProfile} style={{display:"none"}} multiple={true}/>
				  </React.Fragment>
				</dd>
			  </dl>
				{ isAge ? 
					<>
						<div>
							<p>본인인증 된 정보로 이름/생년월일/휴대폰번호 입력됩니다.</p>
						</div>
						<div>
							<input id="userPhone"  type="text" readOnly value={userPhone}  placeholder="휴대폰 번호"/>
							{/* <input id="isPass" type="text" name="name" readOnly hidden/> */}
							{/* <label htmlFor="phone" onClick={openPassAuth}>본인인증</label> */}
							<label htmlFor="phone" >본인</label>
						</div>
						<div className="name">
							<input id="userName" type="text" name="name" value={userName} readOnly   placeholder="이름"/>
						<div className="gender">
							<span>성별 : </span>
							<input type="radio" name="gender" id="genderMan" value="MAN" checked={userGndr == "MAN" ? true : false} readOnly /><label htmlFor="gender_m">남</label>
							<input type="radio" name="gender" id="genderWoman" value="WOMAN" checked={userGndr == "WOMAN" ? true : false} readOnly /><label htmlFor="gender_f">여</label>
							</div>
						</div>
						<div>
							<input id="userBirthday" type="date" name="birthday" value={userBirth} readOnly required aria-required="true" data-placeholder="생년월일" max="9999-12-31" /*maxlength={10}*/ />
						</div>
					</>
					:
					<>
						<div>
							<p>14세 미만의 경우 보호자 연락처는 필수입니다.</p>
						</div>
						<div>
							<input type="text" id="adertPhone" readOnly value={adertPhone}  placeholder="보호자 휴대폰 번호"/>
							<label htmlFor="phone">보호자</label>
							{/* <label htmlFor="phone" onClick={openPassAuth}>보호자 인증</label> */}
						</div>
						<div>
							<p>본인 소유 핸드폰이 없는 경우 보호자 휴대폰으로도 휴대폰 인증이 가능합니다.</p>
						</div>
						<div>
							<input type="text" value={userPhone} onChange={handleUserPhone} readOnly={phoneReadonly}  placeholder="휴대폰 번호"/>
							<label htmlFor="phone" onClick={sendPhoneVerification}>휴대폰 인증</label>
						</div>
						<div ref={divPhoneCheckRef} style={{display:'none'}}>
							<input  ref={phoneCheck} type="text" value={phoneCheckNum} onChange={handlePhoneCheckNum} readOnly={phoneReadonly} placeholder="인증번호"/>
							<label htmlFor="phone"  onClick={chechPhone}>인증 확인</label>
						</div>
						<div>
							<p hidden={isTimer}>인증번호 유효 시간 : {Math.floor(checkTimer/60)} : {(checkTimer%60).toString().padStart(2,"0")}</p>
						</div>
						<div className="name">
							<input type="text" name="name" value={userName} onChange={handleUserName} placeholder="이름"/>
							<div className="gender">
							<span>성별 : </span>
							<input type="radio" name="gender" value="MAN" checked={userGndr == "MAN" ? true : false} onChange={handleUserGndr}/><label htmlFor="gender_m">남</label>
							<input type="radio" name="gender" value="WOMAN" checked={userGndr == "WOMAN" ? true : false} onChange={handleUserGndr} /><label htmlFor="gender_f">여</label>
							</div>
						</div>
						<div>
							<input type="date" name="birthday" value={userBirth} onChange={handleUserBirth}required aria-required="true" data-placeholder="생년월일" max="9999-12-31" /*maxlength={10}*/ />
							<label htmlFor="birthday">날짜선택</label>
							<p>연도 클릭 시 연도 변경가능합니다.</p>
						</div>
						
					</>
					}
				<div className="address">
					<input type="text" name="address" value={userAddr} onChange={handleUserAddr} readOnly={true} placeholder="주소"/>
					<label htmlFor="address1" onClick={clickDaumAddr}>주소찾기</label>
					<Modal isOpen={isOpen} ariaHideApp={false} >
						<DaumPostcode onComplete={completeDaumAddr} /*height="100%"*/ />
					</Modal>
					<input type="text" name="address" value={userDtlAddr} onChange={handleUserDtlAddr} placeholder="상세주소 입력"/>
				</div>
			</div>
		  </section>
		  {/* <!-- wish --> */}
		  <section className="area_event" defaultValue={""}>
			<div className="title">관심지역 및 종목</div>
			<div className="form">
				<LareaAddr onSelectedLareaAddr={onSelectedLareaAddr}></LareaAddr>
			  {/* <div className="area">
				<span>관심지역 : </span>
				<select name="area1">
				  <option>시 / 도</option>
				  <option>서울</option>
				  <option>경기</option>
				  <option>강원</option>
				</select>
				<select name="area2">
				  <option>시 / 군 / 구</option>
				</select>
			  </div> */}
			  <div className="event">
				<p>관심종목 1개 이상 5개 이하 필수선택</p>
				<GameItemProvider onSelectedValuesChange={onSelectGameItemChange} userSelectItem={null}></GameItemProvider>
			  </div>
			</div>
		  </section>
	  
		  <div className="t_ct">
			<button className="btn_type1 complete" onClick={clickJoin}>회원가입 완료</button>
		  </div>
	   
		  <div className="tx_type02 pc"><b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</div>
		  
		</div>
		</Layout>
	);
  }
		
  export default Join;