// kakao.tsx
import React from 'react';
import { useEffect } from "react";
import { NAVER_CLIENT_ID,NAVER_CLIENT_SECRET, API} from '../../config/config.tsx';
import {fn_axios} from '../../common/common.tsx';
import Layout from '../../layout/Layout.tsx';

const NaverToken = () => {
  const params= new URL(document.location.toString()).searchParams;
  const code = params.get('code');
  const state = params.get('state');
  console.log(code);
  console.log(state);

  const getNaverToken = () => {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/v1/user/loginNaver`,
      params : {  clientId : `${NAVER_CLIENT_ID}`
                , code : code
                , state : state
                , clientScret : `${NAVER_CLIENT_SECRET}`}
    }
    fn_axios(param,naverLoginCallback,null);
  }
  const naverLoginCallback = (res) => {
    if(res.data.status) {
      alert(res.data.message);
      window.location.href = "/";
    } else {
      alert(res.data.message);
      window.location.href = "/user/login";
    } 
  } 
  useEffect(() => {
    if(!code) return;
    getNaverToken();
  }, [])

return (
  <Layout>
  <div className="contents join">
    <h1 style={{textAlign: 'center'}}>잠시만 기다려 주세요!<br/>로그인 중입니다.</h1>
  </div>
  </Layout>
)
}
  export default NaverToken;