import React from "react";
import Sidebar from "./AdminSidebar.tsx";
import AdminHeader from "./AdminHeader.tsx";

const AdminLayout = (props: { children: React.ReactNode }) => {
    return (
        <div className="admin-layout">
            <Sidebar />
            <div className="admin-main-content">
                <AdminHeader />
                <div className="admin-area">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
