import React from "react";
import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";
import CmptDetailMain from "./CmptDetailMain.tsx";
import CmptDetailGame from "./CmptDetailGame.tsx";
import CmptDetailItem from "./CmptDetailItem.tsx";
import CmptDetailNotice from "./CmptDetailNotice.tsx";

import {  useLocation } from 'react-router-dom';
import {useState,useEffect} from 'react';
import moment from "moment";

import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';
import { userInfo } from "os";


function CmptDetail() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const pageNum = queryParams.get('pageNum');
  const bnnrLId = queryParams.get('bnnrLId');
  const cmptStateDvisCd = queryParams.get('cmptStateDvisCd');
  const searchMonth  = queryParams.get('searchMonth');
  const searchKeyword = queryParams.get('searchKeyword');
  const prevCmptLId = queryParams.get('prevCmptLId');
  const gameKindNm = queryParams.get('gameKindNm');
  const sourceUrl = queryParams.get('sourceUrl');

  const [userInfo, setUserInfo] = useState(null);

  const [cmpt, setCmpt] = useState(null);
  const [cmptGym, setCmptGym] = useState([]);
  const [isCmptItrst, setIsCmptItrst] = useState(false);
  const [activeTab, setAtiveTab] = useState(queryParams.get('activeTab') == null ? "main" : queryParams.get('activeTab'));
  const [divClass, setDivClass] = useState("");
  const [divClass2, setDivClass2] = useState("");
  const [cmptAtchFileList, setCmptAtchFileList] = useState([]);
  const [cmptEnterMmntList, setCmptEnterMmntList] = useState([]); 

  const getUser = () => {
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/getUser`,
			params : {userId:null}
		}
		fn_axios(param,callbackGetUser,null);
	};

  const callbackGetUser = (res) => {
    setUserInfo(res.data.object);
  }

  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getSlctCmpt`,
        params: {cmptLId : cmptLId
                , userId : getCookieUserId()
                , bnnrLId : bnnrLId
        },
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  
    console.log(res.data);

    // setSignguDataList(resList);
    setCmpt(res.data.map.cmpt[0]);
    setCmptGym(res.data.map.cmptGym);
    setCmptAtchFileList(res.data.map.cmptAtchFile);
    setCmptEnterMmntList(res.data.map.cmptEnterMmntList);
    setIsCmptItrst(res.data.map.cmpt[0].userItrst != null);
  }

  const moveList = () => {
    if(sourceUrl === "cmptItrst") {
      window.location.href = `/user/userCmptItrstList?pageNum=${pageNum}&cmptStateDvisCd=${cmptStateDvisCd}&searchKeyword=${searchKeyword}`;
      return;
    } if(sourceUrl === "reqCmpt") {
      window.location.href = `/user/userReqCmptList?pageNum=${pageNum}&cmptStateDvisCd=${cmptStateDvisCd}&searchKeyword=${searchKeyword}`;
      return;
    } else {
      window.location.href = `/cmpt/cmptList?pageNum=${pageNum}&cmptStateDvisCd=${cmptStateDvisCd}&searchMonth=${searchMonth}&searchKeyword=${searchKeyword}`;
      return;
    }
  }

  const loadingTag = () => {
    if(activeTab == "game") {
      return <CmptDetailGame  propCmpt={cmpt} ></CmptDetailGame>;
    } else if(activeTab == "notice") {
      return <CmptDetailNotice  propCmpt={cmpt} ></CmptDetailNotice>; 
    } else if(activeTab == "item") {
      return <CmptDetailItem  propCmptEnterMmntList={cmptEnterMmntList} ></CmptDetailItem>;
    } else {
      return <CmptDetailMain  propCmpt={cmpt} propCmptAtchFileList={cmptAtchFileList} propCmptGym={cmptGym}></CmptDetailMain>;
    }
  }
  const loadingLinkList = () => {
    if(activeTab == "notice" || activeTab == "game") {
      return null;
    } else {
      return <div className="paging_arrow pc">
      {/* <a href="#" className={prevCmptLId != null  ? "prev" : "prev end"}></a> */}
      <a href="#" onClick={moveList}>목록으로 돌아가기</a>
      {/* <a href="#" className={prevCmptLId != null  ? "next" : "next end"}></a> */}
    </div>;
    }
  }

  const clickHeart = () => {
    if(!getCookieUserId()) {
      alert("로그인 후 이용 바랍니다.");
      return false;
    } else {
      updateHeart();
    }
  }
  const updateHeart = () => {
    const param = {
      method: 'GET',
      url:  `${API.BACKEND}/${API.VERSION}/cmpt/updateCmptItrst`,
      params: {cmptLId : cmptLId
              ,userId : getCookieUserId()
            },
    };
    fn_axios(param, callbackClickHeart, null);
  }

  const callbackClickHeart = (res) => {  
    console.log(res);
    setIsCmptItrst(res.data.object);
  }

  const clikcReqs = () => {
    if(!getCookieUserId()) {
      alert("로그인 후 이용 바랍니다.");
      window.location.href ="/user/login";
      return false;
    } else {
      // window.location.href ="/req/cmptReqs?cmptLId=" +  (cmpt == null ? "" : cmpt.cmptLId) +"&gameKindNm=" + gameKindNm;
      if(cmpt != null && new Date(cmpt.cmptReqStrDtm) > new Date()) {
        alert(`참가 신청 ${moment(cmpt.cmptReqStrDtm).format('YYYY-MM-DD HH:mm')} 부터 입니다.`);
        return false;
      } else if(cmpt != null && new Date(cmpt.cmptReqEndDtm) < new Date()) {
        alert("참가신청이 종료되었습니다");
        return false;
      } else if(cmpt != null && cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02"){
        // 간편 회원가입의 경우 기본 정보 입력을 위해서 회원정보 수정 화면으로 이동.
        if(userInfo != null && ! userInfo.userDtlAddr ) {
          alert("간편 회원가입한 사용자는 기본정보(주소, 선호지역, 선호 종목) 입력 후 참가 신청 바랍니다.");
          window.location.href = "/user/updateuser"; 
          return;
        }

        window.location.href ="/req/cmptReqs?cmptLId=" +  (cmpt == null ? "" : cmpt.cmptLId) +"&gameKindNm=" + gameKindNm;
      } else {
        alert("참가 신청 기간이 아닙니다.");
        return false;
      }
      
    }
  }

  const clickMain = () => {
    setAtiveTab("main");
  }
  const clickGame = () => {
    setAtiveTab("game");
  }
  const clickNotice = () => {
    // alert("문의는 우리그라운드 카카오톡채널로 문의 바랍니다.");
    setAtiveTab("notice");
  }
  const clickItem = () => {
    setAtiveTab("item");
  }


  useEffect(() => {
    if(cmpt == null) {
      getCmpt();
      getUser();
    } else{
    }
  }, [cmpt]); 
  useEffect(() => {
    if(activeTab == "game") {
      setDivClass("category");
      setDivClass2("category_content");
    } else if(activeTab == "notice") {
      setDivClass("s_notice");
      setDivClass2("notice_content");
    } else if(activeTab == "item") {
      setDivClass("prize");
      setDivClass2("prize_content");
    } else {
      setDivClass("summary");
      setDivClass2("summary_content");
    }
    
  }, [activeTab]); 
  return (
 <Layout>
  <div className="contents contest">

    <div className="top_line">
      <h1 className="sub_title pc">{cmpt == null ? null : cmpt.gameKindNm}대회</h1>
      <h1 onClick={moveList} className="sub_title mo">대회참가 / {cmpt == null ? null : cmpt.gameKindNm}</h1>
      <a href="#" onClick={moveList} className="btn_return pc">
        목록으로 돌아가기
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <section className={divClass}>
      {/* <!-- menu tab & poster --> */}

      {/* <!-- mo ver title --> */}
      <h2 className="mo"><span>{cmpt == null ? null : ((cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" || new Date(cmpt.cmptReqEndDtm) > new Date()) ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
            </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
      {/* <!-- //mo ver title --> */}

      <div className="sub_menu">
        <div className="tab">
          <a href="#" onClick={clickMain} className={activeTab == "main" ? "active":""}>대회요강</a>
          <a href="#" onClick={clickGame} className={activeTab == "game" ? "active":""}>세부종목</a>
          <a href="#" onClick={clickNotice} className={activeTab == "notice" ? "active":""}>알림/문의</a>
          <a href="#" onClick={clickItem} className={activeTab == "item" ? "active":""}>경품/기념품</a>
        </div>
        <div className="poster">
          <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
        </div>
        <div className="btn_line">
          <button className="btn_type1 apply" onClick={clikcReqs}>참가신청하기</button>
          <button className={isCmptItrst? "btn_heart_red" : "btn_heart"} onClick={clickHeart}>좋아요</button>
        </div>
        <p className="tx_type04">
          {(cmpt != null && cmpt.cmptLId == '240821_GAME_KIND_0016_000001_0001') ? 
          ""
          :  
          <>
          <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
          <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
          <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </>
        }
            
        </p>
      </div>
      {/* <!-- //menu tab & poster --> */}

      {/* <!-- content --> */}
      <div className={divClass2}>
        {/* <!-- pc ver title --> */}
        <div className="title pc"> 
          <h2><span>
          {cmpt == null ? null : ((cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" || new Date(cmpt.cmptReqEndDtm) > new Date())  ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
            (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
          </span>
          &nbsp; {cmpt == null ? null : cmpt.cmptLNm} </h2>
          <div className="tx_type04">
          {(cmpt != null && cmpt.cmptLId == '240821_GAME_KIND_0016_000001_0001') ? 
          ""
          :  
          <>
          <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
            <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
            <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </>
        }
          </div>
        </div>
        {/* <!-- //pc ver title --> */}

        {/* content  */}
        {cmpt != null ? loadingTag() : null}
        
        {/* <!-- btn apply --> */}
        <div className="btn_line mo">
        <button className="btn_type1 apply" onClick={clikcReqs}>참가신청하기</button>
          <button className={isCmptItrst ? "btn_heart_red" : "btn_heart"} onClick={clickHeart}>좋아요</button>
          <p className="tx_type04">
          {(cmpt != null && cmpt.cmptLId == '240821_GAME_KIND_0016_000001_0001') ? 
          ""
          :  
          <>
          <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
            <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
            <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
          </>
        }
          </p>
        </div>
        {/* <!-- //btn apply --> */}

        {/* <!-- paging --> */}
        
        {loadingLinkList()}
        {/* <!-- //paging --> */}
      </div>
      {/* <!-- //content --> */}
    </section>

    <Slider></Slider>
  </div>
  </Layout>
  );
}
export default CmptDetail;