import React, { useState, useEffect, useRef } from 'react';
import { fn_axios } from './common.tsx';
import { API } from '../config/config.tsx';


interface GameItemProviderProps {
  onSelectedValuesChange: (selectedValues: any) => void;
  userSelectItem: any;
}


const GameItemButton = ({ selectedButtons, renderButtons }) => {
  const gameItemRef = useRef(null);

  return (
    <div ref={gameItemRef}>
      {renderButtons()}
    </div>
  );
};

const GameItemProvider : React.FC<GameItemProviderProps> = ({ onSelectedValuesChange, userSelectItem })=> {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [gameItems, setGameItems] = useState([]);
  const [isInit, setIsInit] = useState(true);
  const gameItemRef = useRef(null);


  const initValue = () => {
    if(userSelectItem != null &&  gameItems.length > 0) {
      var gameItemsList = [];
      setSelectedButtons([]);
      for(var j = 0; j < userSelectItem.length ; j++ ) {
        for(var i = 0; i < gameItems.length ; i++ ) {
          if(gameItems[i].gameKindCd == userSelectItem[j]) {
            // setSelectedButtons([i]);
            // setSelectedButtons([...selectedButtons, i]);
            gameItemsList.push(i);
          }
        }
      }
      if(gameItemsList.length > 0) {
        setSelectedButtons(gameItemsList);
      }
    }
  }


  const handleButtonClick = (index) => {
    const selectedIndex = selectedButtons.indexOf(index);

    if (selectedIndex !== -1) {
      const updatedButtons = [...selectedButtons];
      updatedButtons.splice(selectedIndex, 1);
      setSelectedButtons(updatedButtons);
    } else if (selectedButtons !== null && selectedButtons.length === 5) {
      alert('최대 5개만 선택 가능합니다.');
      return;
    } else {
        setSelectedButtons([...selectedButtons, index]);
    }
    if (onSelectedValuesChange) {
        const returnList = [];
        for(var i = 0; i < selectedButtons.length ; i++ ) {
            if(selectedButtons[i] !== index) {
                returnList.push(gameItems[selectedButtons[i]].gameKindCd)
            }
        }
        // 방금 선택한 항목 추가.
        if(selectedIndex === -1) {
            returnList.push(gameItems[index].gameKindCd)
        }
        
        onSelectedValuesChange(returnList);
      }
  };

  const renderButtons = () => {
    if (gameItems.length === 0) {
      return [];
    }

    if(isInit) {
      setIsInit(false);
      initValue();
    }
    
    return gameItems.map((obj, index) => (
      <button
        key={index}
        className={selectedButtons.includes(index) ? 'active' : ''}
        onClick={() => handleButtonClick(index)}
        value={obj.gameKindCd}
      >
        {selectedButtons.includes(index) && <p>{selectedButtons.indexOf(index) + 1}</p>}
        {obj.gameKindNm}
      </button>
    ));
  };

  // 경기 종목 조회.
  const getGameItem = () => {
    const param = {
      method: 'GET',
      url: `${API.BACKEND}/v1/gameItem/getGameItem`,
      params: null,
    };
    fn_axios(param, callBackGetGameItem, null);
  };

  const callBackGetGameItem = (res) => {
    const data = res.data;

    if (data.status) {
      const gameItemList = data.object;

      setSelectedButtons([]);
      if (gameItemRef.current) {
        gameItemRef.current.innerHTML = '';
      }
      setGameItems(gameItemList);
    } else {
    }
  };

  useEffect(() => {
    getGameItem();
  }, []);
  
 
  return (
    <GameItemButton selectedButtons={selectedButtons} renderButtons={renderButtons} />
  );
};

export default GameItemProvider;