import React from 'react';
import {useState,useEffect} from 'react';
import {getAccessToken} from "../../common/common.tsx";
import { HelmetProvider, Helmet } from 'react-helmet-async';
import {fn_axios,fn_post_fileUpload_axios} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';

import Layout from '../../layout/Layout.tsx';
import Slider from "../../layout/Slider.tsx";

function Point() {
    const today = new Date();
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [point, setPoint] = useState("");
    const [startDt, setStartDt] = useState((today.getMonth() == 0 ? today.getFullYear() - 1 : today.getFullYear())+ "-" + String(today.getMonth() == 0 ? 12 : today.getMonth()).padStart(2,"0") + "-" + String(today.getDate()).padStart(2,"0"));
    const [endDt, setEndDt] = useState(today.getFullYear() + "-" + String(today.getMonth() + 1 ).padStart(2,"0") + "-" + String(today.getDate()).padStart(2,"0"));
    const [pageNum, setPageNum] = useState(1);
    const [pageMaxNum, setPageMaxNum] = useState(0);
    const [pageCnt, setPageCnt] = useState(10);
    const [paging, setPaging] = useState([]);
    const [pointList, setPointList] = useState([]);
    


    const getUser = () => {
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/getUser`,
			params : {userId:null}
		}
		fn_axios(param,callbackGetUser,null);
	};

    const callbackGetUser = (res) => {
        setUserName(res.data.object.userNm);
        setPoint(res.data.object.point);
        setUserId(res.data.object.userId);
    }

    const getPointPage = (num) => {
        const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/point/getPointPaging`,
			params : {userId:userId
                    , pageNum : num
                    , startDt : startDt
                    , endDt : endDt
                }
		}
		fn_axios(param,callbackGetPointPage,null);
    }

    const callbackGetPointPage = (res) => {
        // console.log(res);
        setPointList(res.data.object);
        settingPasing(parseInt(res.data.map.pointCnt/pageCnt));
    }

    const settingPasing = (maxNum) => {
        setPageMaxNum(parseInt(maxNum));
        console.log(maxNum);
        // var start = (pageNum > 2 ? ( (maxNum - pageNum > 4) ? pageNum - 2 : maxNum - pageNum) : 1);
        var start = (maxNum <= 5 || pageNum <= 2) ? 1 : pageNum - (maxNum - pageNum <= 2 ? (4 - (maxNum - pageNum)) : 2)  ;
        var end = (maxNum <= 5 || maxNum - pageNum <= 2) ? maxNum : (pageNum < 3 ? 3 : pageNum) +2;
        var tempList = [];
        console.log("start : " + start + " end : " +end);
        console.log("pointList.length " + pointList.length);

        if(maxNum != 0 ) {
            for (var i = start; i <= end; i++) {
                tempList.push(i);
            }
        }
        setPaging(tempList);
    }

    const changeStartDt = (e) => {
        setStartDt(e.target.value);
    }
    const changeEndDt = (e) => {
        setEndDt(e.target.value);
    }
    const searchPaging = () => {
        getPointPage(pageNum);
    }


    const onClickPaging = (e) => {
        setPageNum(parseInt(e.target.text));
        //getPointPage(e.target.text);

        return null; 
    }

    useEffect(() => {
        // 이 곳에서 업데이트된 userName 값을 사용할 수 있습니다.
        getPointPage(pageNum);
      }, [pageNum]); 
    
    useEffect(() => {

        //로그인중이면 홈화면으로 이동.
        if(getAccessToken() == null ) {
            window.location.href = "/";
        } else {
            getUser();
        }
        }, [])
    return (
        <Layout>
        
        <div className="contents mypage point">
            {/* <!-- mo ver id --> */}
            <div className="my_profile">
                <div className="my_id">
                    <span>{userName}</span> <b><span>{point}</span> point</b> 
                </div>
            </div>
            {/* <!-- //mo ver id --> */}

            <section className="list_type1">
            {/* <!-- list top search --> */}
                <div className="bar">
                    {/* <!-- pc ver id --> */}
                    <div className="sub_title2 pc">My Page<span> / 포인트 정보</span></div>
                    {/* <!-- //pc ver id --> */}
                    <div className="my_point pc">{point} point</div>
                    <div className="date_search">
                    <div>
                        <input type="date" name="first_date" value={startDt} id="first_date" onChange={changeStartDt} required aria-required="true" max="9999-12-31" maxLength={10}/>
                        <label htmlFor="first_date"></label>
                    </div>
                    <span>~</span>
                    <div>
                        <input type="date" name="lsat_date" value={endDt} id="last_date" onChange={changeEndDt} required aria-required="true" max="9999-12-31" maxLength={10}/>
                        <label htmlFor="last_date"></label>
                    </div>
                    <button className="btn_date" onClick={searchPaging}>기간검색</button>
                    </div>
                </div>
                {/* <!-- //list top search --> */}
                {/* <!-- table --> */}
                {/* <!-- pc ver table --> */}
                <div className="table_type1 pc">
                    <table>
                    <colgroup>
                        <col style={{width:"3%"}}/>
                        <col style={{width: "19%"}}/>
                        <col style={{width: "auto"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "10%"}}/>
                        <col style={{width: "8%"}}/>
                    </colgroup>
                    <thead>
                        <th>No.</th>
                        <th>일자</th>
                        <th className="info">내용</th>
                        <th>적립</th>
                        <th>차감</th>
                        <th>합계</th>
                    </thead>
                    <tbody>
                    {pointList.map((item, index) => (
                        <tr key={index}>
                        <td>{item.no}</td>
                        <td>{item.pointUseDtm}</td>
                        <td className="info">{item.pointUseCntn}</td>
                        <td>{item.acqsPoint}</td>
                        <td>{item.usePoint}</td>
                        <td className="point">{item.blncPoint}</td>
                        </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
                {/* <!-- //pc ver table --> */}
                {/* <!-- mo ver table --> */}
                <div className="table_type1 mo">
                    <table>
                    <colgroup>
                        <col style={{width: "17%"}}/>
                        <col style={{width: "auto"}}/>
                        <col style={{width: "16%"}}/>
                        <col style={{width: "18%"}}/>
                        <col style={{width: "27%"}}/>
                    </colgroup>
                    <thead>
                        <th>No.</th>
                        <th>일자</th>
                        <th>적립</th>
                        <th>차감</th>
                        <th>합계</th>
                    </thead>
                    <tbody>
                    {pointList.map((item, index) => (
                         <tr key={index}>
                         <td>{item.no}</td>
                         <td>{item.pointUseDtm}</td>
                         <td className="info">{item.pointUseCntn}</td>
                         <td>{item.acqsPoint}</td>
                         <td>{item.usePoint}</td>
                         <td className="point">{item.blncPoint}</td>
                         </tr>
                    ))}
                    </tbody>
                    </table>
                </div>
                {/* <!-- //mo ver table --> */}
                <div className="paging">
                    {paging.map((item, index) => (
                        <a href="#" key={item} onClick={onClickPaging} className={pageNum == item ? "on" :""}>{item}</a>
                    ))}
                </div>
            {/* <!-- //table --> */}
            </section>
            <Slider></Slider>
            <div className="tx_type02 pc"><b>당신이 원하는 대회를 원하는 장소에서</b>당신의 대회를 알리고 싶다면, <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</div>
        </div>
        </Layout>
    );
}

export default Point;