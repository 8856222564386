import React from "react";
import moment from "moment";
import {useState,useEffect} from 'react';
import { useLocation } from 'react-router-dom';

import Layout from "../../layout/Layout.tsx";
import {fn_axios,getCookieUserId} from '../../common/common.tsx';
import {API} from '../../config/config.tsx';

function CmptReqsPayComplet() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const cmptLId = queryParams.get('cmptLId');
  const payId  = queryParams.get('payId');
  const usePoint  = queryParams.get('usePoint');
  const cpmtLNm  = queryParams.get('cpmtLNm');
  const gameKindNm  = queryParams.get('gameKindNm');
  const [cmpt, setCmpt] = useState(null);
  const [cmptGym,setCmptGym] = useState([]);
  const [user, setUser] = useState(null);
  const [EnterReqList, setEnterReqList] = useState([]);
  const [fare, setFare] = useState(0);
  
  const [confirmPoint, setConfirmPoint] = useState(0);

  console.log(queryParams.getAll);

  const getCmpt = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/cmpt/getCmpt`,
        params: {cmptLId : cmptLId},
      };
      fn_axios(param, callbackGetCmpt, null);
  }
  const callbackGetCmpt = (res) => {  
    // setSignguDataList(resList);
    setCmpt(res.data.map.cmpt[0]);
    setCmptGym(res.data.map.cmptGym);
  }
  const getUser = () => {
    const param = {
        method: 'GET',
        url:  `${API.BACKEND}/${API.VERSION}/user/getUser`,
        params: {userId : getCookieUserId()},
      };
      fn_axios(param, callbackGetUser, null);
  }
  const callbackGetUser = (res) => {  
    // setSignguDataList(resList);
    setUser(res.data.object);
  }

  const updateEnterReqComplet = () => {
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/${API.VERSION}/req/updateEnterReqComplet`,
      params : {    cmptLId: cmptLId
                    // , cmptGameId : cmptGameId
                    , payId : payId
                    , reqUserId : getCookieUserId()
                    , usePoint : usePoint ? usePoint : 0
                }
      }
    fn_axios(param,callbackUpdateEnterReqComplet,null);
  }
    
  const callbackUpdateEnterReqComplet = (res) => {
    setEnterReqList(res.data.object);
    var list = res.data.object;
    var tempFare = 0;
    for(var i = 0 ; i < list.length ; i++ ) {
      tempFare += list[i].enterFare;
    }
    setFare(tempFare);
    if(tempFare == 0) {
      alert("신청 금액을 계좌이체해야 최종 완료됩니다.");
    } else {
      // 결제 금액이 0원 이상인 경우만
      snedGameCompetition()
    }
  }

  const snedGameCompetition = () => {
    const param = {
      method : 'POST',
      url : `${API.BACKEND}/${API.VERSION}/sms/sendGameCompetition`,
      params : {
        cmptLId : cmptLId,
        reqUserId : getCookieUserId()
      }
    }
    fn_axios(param,callBackGameCompetition,null);
  }
  const callBackGameCompetition = (res) => {
    console.log(res);
  }

  useEffect(() => {
    if(cmpt == null) {
      getUser();
      getCmpt();
      updateEnterReqComplet();
      // snedGameCompetition();
    }
  }, []); 

  return (
    <Layout>
    <div className="contents contest">
      <div className="top_line">
          <h1 className="sub_title pc">{cmpt == null ? null : cmpt.gameKindNm}대회</h1>
          <h1 className="sub_title mo">대회참가 / {cmpt == null ? null : cmpt.gameKindNm}</h1>
          <a href="#" onClick={() => window.location.href = `/cmpt/cmptList`} className="btn_return pc">
            목록으로 돌아가기
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
  
        <section className="application payment">
          {/* <!-- menu tab & poster --> */}
  
          {/* <!-- mo ver title --> */}
          <h2 className="mo"><span>{cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
                </span>&nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
          {/* <!-- //mo ver title --> */}
  
          <div className="sub_menu">
            <div className="tab">
            <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=main&gameKindNm="+gameKindNm} >대회요강</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=game&gameKindNm="+gameKindNm} >세부종목</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=notice&gameKindNm="+gameKindNm} >알림/문의</a>
              <a href={"/cmpt/cmptDetail?cmptLId=" + cmptLId + "&activeTab=item&gameKindNm="+gameKindNm} >경품/기념품</a>
            </div>
            <div className="tit">참가신청 완료</div>
            <div className="poster">
            <img src={cmpt == null ? null : cmpt.cmptPcAtchFileUrl} alt={cmpt == null ? null : cmpt.cmptPcAtchFileNm} />
            </div>
          </div>
          {/* <!-- //menu tab & poster --> */}
  
          {/* <!-- content --> */}
          <div className="application_content">
            {/* <!-- pc ver title --> */}
            <div className="title pc"> 
              <h2><span>
              {cmpt == null ? null : (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(cmpt.cmptReqDday) >= 0 ? "D-" + Math.abs(cmpt.cmptReqDday) : "D+" + Math.abs(cmpt.cmptReqDday) ) : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(cmpt.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                (cmpt.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감")))} 
              </span>
              &nbsp; {cmpt == null ? null : cmpt.cmptLNm}</h2>
              <div className="tx_type04">
                <span>{cmpt == null ? null : cmpt.regrDtm}</span> &#47;
                <span>조회 : {cmpt == null ? null : cmpt.slctNum}</span> &#47;
                <span><b>참가 : {cmpt == null ? 0 : cmpt.cmptEnterPerson}</b></span>  
              </div>
            </div>
            {/* <!-- //pc ver title --> */}
  
            {/* <!-- content info --> */}
            <div className="info step3">
              {/* <!-- select --> */}
              <div className="search_box">
                <div className="tit">
                  <b>Step4. 신청 결과 확인</b>
                </div>
              </div>
              {/* <!-- //select --> */}
              
              <div className="list">
                <ul>
                  <li>
                    <span className="tit">대회명</span>
                    <span><b>{cmpt == null ? null : cmpt.cmptLNm}</b></span>
                  </li>
                  <li>
                    <span className="tit">기간</span>
                    <span>{cmpt == null ? null : moment(cmpt.cmptStrDt).format('YYYY-MM-DD')+ " ~ " + moment(cmpt.cmptEndDt).format('YYYY-MM-DD')}</span>
                  </li>
                  <li>
                    <span className="tit">개최장소</span>
                    {cmptGym == null ?  null : cmptGym.map((item, index) => (
                    <span  style={{cursor:"POINTER",textDecoration:"underline"}}onClick={() => window.location.href = `/gym/gymDetail?gymId=${item.gymId}`}>{item.gymNm }</span>
                    ))}
                  </li>
                </ul>
                <ul>
                  <li>
                    <span className="tit">이름</span>
                    <span><b>{user == null ? null : user.userNm}</b></span>
                  </li>
                  <li>
                    <span className="tit">생년월일</span>
                    <span>{user == null ? null : user.userBirth.substring(0,4) + "년 " + user.userBirth.substring(4,6) + "월 " + user.userBirth.substring(6,8) +"일"}</span>
                  </li>
                  <li>
                    <span className="tit">주소</span>
                    <span>{user == null ? null : user.userAddr + " " + user.userDtlAddr}</span>
                  </li>
                  <li>
                    <span className="tit">전화번호</span>
                    <span>{user == null ? null : user.userPhone.substring(0,3) + "-" + user.userPhone.substring(3,7) + "-" + user.userPhone.substring(7,12)}</span>
                  </li>
                  <li>
                    <span className="tit">이메일</span>
                    <span>{user == null ? null : user.userEmail}</span>
                  </li>
                  {/* 임시 : 무도캠프로 인해 임시로 진행 */}
                  {cmpt == null || cmpt.cmptLId != '240318_GAME_KIND_0004_000001_0001'  ? null 
                  :
                  <li>
                    <span className="tit">입금계좌</span>
                    <span>우리은행 1005-404-602627 (예금주: 김소연(우리그라운드))</span>
                  </li>
                  }
                  <li className="details">
                    <span className="tit">세부종목</span>
                    <div className="detail_box">
                      <span className="tit mo">세부종목</span>
                      {EnterReqList == null ? null : EnterReqList.map((item, index) => (
                      <div  key={index + "req"}>
                        <div>
                          <p className="first_line">
                          {item.gameDivsLValue0 == null || item.gameClsfKindCd0 == 'GAME_CLSF_KIND_CD_05' ? null : <span>{item.gameDivsNm0 + " : " + item.gameDivsLValue0}</span>}
                          {item.gameDivsLValue1 == null || item.gameClsfKindCd1 == 'GAME_CLSF_KIND_CD_05' ? null : <span>{item.gameDivsNm1 + " : " + item.gameDivsLValue1}</span>}
                          {/* {item.gameDivsLValue2 == null ? null : <span>{item.gameDivsLValue2}</span>} */}
                            <span className="pay1">금액 : {item.enterFare}</span>
                          </p>
                          <p className="tx_type05">{item.teamYn == 'N' ? "" :  " 팀 : " + (item.enterTeamNm  == null ? '팀없음' : item.enterTeamNm ) + " / "}&nbsp;&nbsp;&nbsp;{item.prtnYn != "Y" ?   ""  : "파트너 : " + item.prtnUserNm.slice(0, -1) + ' * '}</p>
                        </div>
                        {item.gameDivsLValue1 == null || item.gameClsfKindCd1 != 'GAME_CLSF_KIND_CD_05' ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm1 + " : "}</b> <span>{item.cmptReqGameDivsValue1}</span></div>}
                          {item.gameDivsLValue2 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm2 + " : "}</b> <span>{item.cmptReqGameDivsValue2 || item.gameDivsLValue2}</span></div>}
                          {item.gameDivsLValue3 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm3 + " : "}</b> <span>{item.cmptReqGameDivsValue3 || item.gameDivsLValue3}</span></div>}
                          {item.gameDivsLValue4 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm4 + " : "}</b> <span>{item.cmptReqGameDivsValue4 || item.gameDivsLValue4}</span></div>}
                          {item.gameDivsLValue5 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm5 + " : "}</b> <span>{item.cmptReqGameDivsValue5 || item.gameDivsLValue5}</span></div>}
                          {item.gameDivsLValue6 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm6 + " : "}</b> <span>{item.cmptReqGameDivsValue6 || item.gameDivsLValue6}</span></div>}
                          {item.gameDivsLValue7 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm7 + " : "}</b> <span>{item.cmptReqGameDivsValue7 || item.gameDivsLValue7}</span></div>}
                          {item.gameDivsLValue8 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm8 + " : "}</b> <span>{item.cmptReqGameDivsValue8 || item.gameDivsLValue8}</span></div>}
                          {item.gameDivsLValue9 == null ? null : <div style={{marginTop:"5px"}}> <b>{item.gameDivsNm9 + " : "}</b> <span>{item.cmptReqGameDivsValue9 || item.gameDivsLValue9}</span></div>}
                      </div>
                    ))}
                    </div>
                  </li>
                  <li className="total">
                    <span className="tit">결제금액</span>
                    <span className="pay2">{fare.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                  </li>
                  <li className="deduct">
                    <span className="tit">사용 포인트</span> <span>{usePoint == null ? null : usePoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span>
                  </li>
                  <li className="total">
                    <span className="tit tx_type06">최종결제금액</span>
                    <span className="pay3">{(fare - usePoint).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원</span>
                  </li>
                </ul>
              </div>
            </div>
            {/* <!-- //content info --> */}
            <button className="btn_application okay" onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${cmptLId}&gameKindNm=${cmpt == null ? null : cmpt.gameKindNm}`}>결제 완료</button>
            
            
          {/* <!-- //content --> */}
          </div>
        </section>
      </div>
    </Layout>
  );
}
export default CmptReqsPayComplet;